import React from 'react';
import { Button } from '../Button';

export const ErrorPage = () => {
  return (
    <div
      className="flex flex-col items-center justify-center bg-[#f7f8f9] pb-15 pt-30 text-center text-[24px] leading-[44px] lg:pb-20 lg:pt-40"
      style={{ color: 'rgba(17, 25, 37, 0.6)' }}
    >
      <div className="relative w-[80%] md:w-[60%] lg:w-[533px]">
        <div className="pb-[40.5253283302%]" />
        <img
          alt="404"
          className="absolute inset-0"
          src="https://p0.meituan.net/smartvenus/d02f0c958c363f4a8bb534f3baec94c946783.png"
        />
      </div>
      <div className="pt-[48px]">An error occurred on the client, please try again</div>
      <div className="pt-4">
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          Retry
        </Button>
      </div>
    </div>
  );
};
