import React from 'react';
import { useRouter } from 'next/router';
import { LangType } from '../LangSelector';
import { Icon } from '../../../../SvgIcon';
import styles from './index.module.scss';

interface PropsItem {
  item: LangType;
  current: string;
  onClick(): void;
}

export const MenuItem = (props: PropsItem) => {
  const { item, current, onClick } = props;
  const router = useRouter();

  const handleLocaleChange = (lItem: LangType) => {
    onClick();
    router.push(router.route, router.asPath, {
      locale: lItem.key,
    });
  };

  return (
    <div key={item.key} className={styles.menuItem} onClick={() => handleLocaleChange(item)}>
      <span>{item.title}</span>
      {current === item.key ? <Icon name="check-mark" width={20} height={20} /> : null}
    </div>
  );
};
