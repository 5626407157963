import React, { useMemo } from 'react';
import NextImage from 'next/image';
import styles from './index.module.scss';
import { ImgCompressOptions, compressImg } from '../../util/imgLoader';
import { supportWebP } from './webpAvailable';

interface Props {
  src: string; // 图片链接
  width?: number; // 图片必须要传宽高
  height?: number; // 图片必须要传入高度
  alt?: string; // 默认的图片提示
  layout?: 'fixed' | 'responsive' | 'fill' | 'intrinsic';
  compressOptions?: ImgCompressOptions;
  [key: string]: any;
}
/**
 * venus域名判断
 * 参考链接：https://km.sankuai.com/page/1287249539
 */
const venusHosts = ['p0.meituan.net', 'p1.meituan.net', 'img.meituan.net'];

export const Image = (props: Props) => {
  const { layout = 'responsive', alt = '美团', src, compressOptions, ...otherProps } = props;

  // 是否支持webp图片。
  const isSupportWebp = useMemo(() => {
    return supportWebP;
  }, []);

  // 判断是否是venus图片
  const isVenusPic = useMemo(() => {
    let flag = false;
    venusHosts.forEach((item) => {
      if (new RegExp(item, 'i').test(src)) {
        flag = true;
      }
    });
    return flag;
  }, [src]);

  let showSrc = src;
  if (compressOptions) {
    showSrc = compressImg({ imageUrl: showSrc, ...compressOptions });
  }
  // 如果支持webp的浏览器加载Venus的webp格式的图片
  if (isSupportWebp && isVenusPic) {
    showSrc = `${showSrc}.webp`;
  }

  // 如果showSrc是空的，那么保持图片的站位符号
  const isNoValidSrc = useMemo(() => {
    if (!src) {
      return true;
    }
    return false;
  }, [src]);

  // 如果不是合法的URL，而且是响应式布局的图片，保持图片的站位
  if (isNoValidSrc && layout === 'responsive') {
    const { width = 1, height = 1 } = otherProps;
    const radio = `${(height / width) * 100}%`;
    return (
      <div className={styles.image}>
        <div className={styles['image-placeholder']} style={{ paddingTop: radio }} />
      </div>
    );
  }

  return <NextImage src={showSrc} {...otherProps} layout={layout} unoptimized alt={alt} />;
};
