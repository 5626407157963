import React from 'react';
// import Link from 'next/link';
import { Link } from '../../../Link';
import style from './mnav.module.scss';
import LinkOutIcon from '../../../LinkOutIcon';

interface Props {
  title: string;
  childData: any;
  onCancel(): void;
  onClose(): void;
}

export const SubMNav = (props: Props) => {
  const { onCancel, childData, title, onClose } = props;

  const { navArrs, bottom } = childData;

  const renderItem = (item: Record<string, any>) => {
    if (item.out) {
      return (
        <a href={item.path} className="flex items-center space-x-4" draggable="false" target="_blank" rel="noreferrer">
          <span>{item.title}</span>
          {item.hasIcon && <LinkOutIcon />}
        </a>
      );
    }
    return (
      <Link href={item.h5Path || item.path} legacyBehavior scroll={false} prefetch={false}>
        <a className={style.subLinks} onClick={onClose}>
          {item.title}
        </a>
      </Link>
    );
  };

  return (
    <div className={style['mnav-menu-child']}>
      <h2 className={style['mnav-submenu-title']}>
        <span onClick={onCancel}>
          <svg width="20px" height="20px" viewBox="0 0 40 40">
            <path
              d="M27.5,35 C27.0116208,35.4879189 26.2202323,35.4877323 25.7320832,34.9995832 L11.3216667,20.5891667 L11.3216667,20.5891667 C10.9963484,20.2637501 10.9963484,19.7362499 11.3216667,19.4108333 L25.7316907,5.00002409 C26.2199759,4.51171231 27.0116641,4.51169075 27.4999759,4.99997591 C27.4999839,4.99998394 27.499992,4.99999197 27.5,5 C27.9882829,5.48828295 27.9882829,6.27994603 27.5,6.76822897 C27.4999826,6.76824637 27.4999652,6.76826377 27.4999478,6.76828116 L14.2666667,20 L14.2666667,20 L27.5003905,33.2329427 C27.9882421,33.7207655 27.9882655,34.5117059 27.5004426,34.9995576 C27.5002951,34.9997051 27.5001476,34.9998526 27.5,35 Z"
              fill="#111925"
            />
          </svg>
        </span>
        <span className={style.subTitle}>{title}</span>
      </h2>
      <div>
        {(navArrs || []).map((arrs: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <h6 className="mb-[30px] mt-3 text-gray-30">{arrs.title}</h6>
              <ul className="mb-5">
                {arrs.items.map((item: Record<string, any>, key: number) => (
                  <li className="mb-[30px]" key={key}>
                    {renderItem(item)}
                  </li>
                ))}
              </ul>
            </React.Fragment>
          );
        })}
        {bottom && (
          <div className={style.baogao}>
            <a href={bottom.path} target="_blank" rel="noreferrer" style={{ color: '#000' }}>
              {bottom.title}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
