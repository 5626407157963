import React, { useEffect, useMemo, useState } from 'react';

export enum ThemeType {
  Dark = 'dark',
  Light = 'light',
}

const darkColor = '#ffffff';
const lightColor = '#111925';

interface ThemeContextType {
  theme: ThemeType;
  themeColor: string;
  isFixed: boolean;
  setTheme(theme: ThemeType): void;
  setFixed(fixed: boolean): void;
}

export const ThemeContext = React.createContext<ThemeContextType>({ theme: ThemeType.Dark, isFixed: false } as any);

export const ThemeProvider = ({ children, theme }: { children: React.ReactNode; theme: ThemeType }) => {
  const [innertheme, setTheme] = useState(ThemeType.Light);
  const [isFixed, setFixed] = useState(false);

  useEffect(() => {
    setTheme(theme);
  }, [theme]);

  const fillColor = useMemo(() => {
    if (isFixed) return lightColor;
    if (theme === 'dark') {
      return darkColor;
    }
    return lightColor;
  }, [isFixed, theme]);
  // 样式提供的样式
  const themeValue = useMemo(() => {
    return {
      theme: innertheme,
      setTheme,
      themeColor: fillColor,
      isFixed,
      setFixed,
    };
  }, [innertheme, fillColor, isFixed]);

  return <ThemeContext.Provider value={themeValue}>{children}</ThemeContext.Provider>;
};
