import React from 'react';
import { Icon } from '../../../../SvgIcon';
import styles from './index.module.scss';

interface Props {
  title: string;
  href: string;
  onClick?(): void;
}

export const OutLink = (props: Props) => {
  const { title, href, onClick } = props;

  return (
    <a className={styles.outlink} href={href} target="_blank" rel="noreferrer" onClick={() => onClick?.()}>
      <span>{title}</span>
      <span className="ml-4">
        <Icon width={20} height={20} name="leftArrow" />
      </span>
    </a>
  );
};
