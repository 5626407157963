import React from 'react';
import { useRouter } from 'next/router';

interface Props {
  href: string; // 页面的地址
  children: React.ReactElement;
  legacyBehavior?: boolean; // 是否
  [key: string]: any;
}
export const Link = (props: Props) => {
  const { legacyBehavior, href: originHref, children, ...otherProps } = props;
  const { locale = 'zh-CN' } = useRouter();

  // const locales = ['zh-CN', 'zh-HK', 'en-US'];
  let href = originHref;
  const isLinks = originHref.startsWith('https://');

  if (!isLinks && locale !== 'zh-CN') {
    if (!href.startsWith(`/${locale}`)) {
      href = `/${locale}${href}`;
    }
  }

  if (legacyBehavior) {
    if (React.Children.count(children) === 1) {
      return React.cloneElement(children, { href });
    }
  }
  if (children.type === 'a') {
    return React.cloneElement(children, { href });
  }

  return (
    <a href={href} {...otherProps}>
      {children}
    </a>
  );
};
