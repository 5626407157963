import React, { useEffect } from 'react';

/**
 * 事件 passive属性主要高速浏览器没有滚动效果
 * @param domRef
 */
export const useScroll = (domRef: React.RefObject<HTMLElement>) => {
  useEffect(() => {
    if (!domRef.current) return () => {};
    const dom = domRef.current;
    // 组织默认行为。
    const handler = (event: TouchEvent) => {
      event.preventDefault();
    };
    dom.addEventListener('touchmove', handler, false);
    return () => {
      dom.removeEventListener('touchmove', handler, false);
    };
  }, [domRef]);
};
