/** @format */

import { useCallback, useEffect, useRef } from 'react';

export const useMotionEvent = (nodeRef: any, callback: any) => {
  const callbackRef = useRef(callback);
  const eventCallback = useRef(() => {
    callbackRef.current();
  });

  const addTransitionEnd = useCallback((ele: HTMLElement) => {
    ele.addEventListener('transitionend', eventCallback.current);
  }, []);

  const removeTransitionEnd = useCallback((ele: HTMLElement) => {
    ele?.removeEventListener('transitionend', eventCallback.current);
  }, []);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(
    () => () => {
      nodeRef.current && removeTransitionEnd(nodeRef.current);
    },
    [nodeRef, removeTransitionEnd],
  );

  return [addTransitionEnd, removeTransitionEnd];
};
