import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import styles from './index.module.scss';
import { LangType } from '../LangSelector';
import { MenuItem } from './MenuItem';
import { DropDown } from './MenuDropDown';
import { DropDownPanel } from './MenuDropDownPanel';
import { OutLink } from './OutLink';
import { items } from '../constants';

export const ZhLang = () => {
  const { locale = 'zh-CN' } = useRouter();

  const item = useMemo(() => {
    return items.find((langItem) => langItem.key === locale);
  }, [locale]);

  return (
    <DropDown
      item={item as LangType}
      renderChild={(toggle, setToggle) => {
        return (
          <DropDownPanel toggle={toggle}>
            {items.slice(0, 2).map((langItem) => {
              return <MenuItem item={langItem} current={locale} key={langItem.key} onClick={() => setToggle(false)} />;
            })}
            {/* 边框 */}
            <div className={styles.itemborder} />

            <OutLink title="English Version" href="/en-US/about-us" />
          </DropDownPanel>
        );
      }}
    />
  );
};
