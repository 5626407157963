import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import type { NextPage } from 'next';
import Head from 'next/head';
import { Router } from 'next/router';
import Script from 'next/script';
import 'url-search-params-polyfill';
import React, { ReactElement, ReactNode } from 'react';
import { ErrorBoundary } from '../components/ErrorBoundary';

import '../style/preflight.css';
import '../globalStyles.scss';
import '../style/mtRichStyle.css';
import '../smartPolyfillStyles.scss';

import { Layout } from '../components/Layout';
import { ThemeProvider, ThemeType } from '../components/Layout/themeContext';
import Share from '../components/Share';
import CookieConsent from '../components/CookieConsent';
import ScreenSize from '../components/ScreenSize';
import { logger } from '../util/logger';
import { getEnv } from '../util/env';
import { Env } from '../util/type';
import { IconSymbol } from '../components/SvgIcon';
import { getAnalyticsTag } from '../util/lx';

Router.events.on('routeChangeComplete', (url) => {
  try {
    window.owl &&
      window?.owl('resetPv', {
        pageUrl: url,
      });
  } catch (e) {
    logger.error('owl上报错误');
  }
});

// Router.events.on('beforeHistoryChange', () => {
//   if (typeof window !== 'undefined') {
//     const htmlElement = document.documentElement;
//     htmlElement.style.scrollBehavior = 'auto';
//     htmlElement.scrollTop = 0;
//   }
// });

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
  theme?: ThemeType;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps: Record<string, any>;
};

const MyApp = ({ Component, pageProps }: AppPropsWithLayout): JSX.Element => {
  const { theme = ThemeType.Light, getLayout } = Component;

  // 如果有自定义layout信息，就采用自定义的layout
  let AppContent: ReactNode = (
    <Layout {...pageProps}>
      <Share {...pageProps}>
        <ErrorBoundary>
          <Component {...pageProps} />
        </ErrorBoundary>
      </Share>
      <CookieConsent {...pageProps} />
    </Layout>
  );

  if (getLayout) {
    AppContent = getLayout(<Component {...pageProps} />);
  }
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="keywords"
          content="美团，美团官网，美团公司，美团合作，美团外卖，外卖，点评，团购，Meituan，Food delivery"
        />
        <meta
          name="description"
          content="美团是一家科技零售公司。美团以“零售 + 科技”的战略践行“帮大家吃得更好，生活更好”的公司使命。自2010年3月成立以来，美团持续推动服务零售和商品零售在需求侧和供给侧的数字化升级，和广大合作伙伴一起努力为消费者提供品质服务。2018年9月20日，美团在港交所挂牌上市。美团始终以客户为中心，不断加大在新技术上的研发投入。美团会和大家一起努力，更好承担社会责任，更多创造社会价值。"
        />
        <meta name="renderer" content="webkit" />
        <meta name="lx:category" content="donation" />
        <meta name="lx:appnm" content="meituanOfficeSite" />
        <meta name="lx:autopv" content="off" />
        <link
          href="https://s3plus.meituan.net/v1/mss_e2821d7f0cfe4ac1bf9202ecf9590e67/cdn-prod/file:1040877d/favicon-mt.ico"
          rel="shortcut icon"
          sizes="any"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0, viewport-fit=cover"
        />
        <link rel="dns-prefetch" href="//lx1.meituan.net" />
        <link rel="dns-prefetch" href="//lx2.meituan.net" />
        <link rel="dns-prefetch" href="//plx.meituan.com" />
        <link rel="dns-prefetch" href="//wreport1.meituan.net" />
        <link rel="dns-prefetch" href="//report.meituan.com" />
        <link rel="dns-prefetch" href="//s3plus.meituan.net" />
        <link rel="dns-prefetch" href="//p0.meituan.net" />
        <link rel="dns-prefetch" href="//www.dpfile.com" />
        <meta name="baidu-site-verification" content="codeva-IhnCcAIJAk" />
        <script dangerouslySetInnerHTML={getAnalyticsTag()} />
      </Head>
      <ScreenSize>
        <IconSymbol />
        <ThemeProvider theme={theme}>{AppContent}</ThemeProvider>
      </ScreenSize>
      <Script
        src="//www.dpfile.com/app/owl/static/owl_1.10.1.js"
        onLoad={() => {
          window.owl &&
            window?.owl('start', {
              project: 'com.sankuai.fspfecap.officeweb',
              devMode: ![Env.staging, Env.production].includes(getEnv()), // st和production都上报正式环境
              pageUrl: window.location.host + window.location.pathname,
              enableLogTrace: true, // 开启串联后端链路能力
              autoCatch: {
                fetch: true,
              },
              ignoreList: {
                // 忽略灵犀的上报ajax错误
                ajax: ['https?://lx1.meituan.net', 'https?://plx.meituan.com'],
                js: [
                  'YsAppBridge is not defined',
                  'runCustomize is not defined',
                  'processRandomSelector is not defined',
                  'insertStyle is not defined',
                  "Cannot set property 'insertStyle' of undefined",
                  'downProgCallback is not defined',
                  'listenKeyboard is not defined',
                  'syncDownloadState is not defined',
                  "Cannot read property '_handleMessageFromNative' of undefined",
                ],
                resource: ['https?://lx.meituan.net/'],
              },
              page: {
                fstPerfAnalysis: true,
                logSlowView: true,
              },
              logan: {
                enable: true, // 开启Logan, 允许前端日志采集以及异常发生时的前置上报
                version: '2.1.4',
                config: {
                  devMode: ![Env.staging, Env.production].includes(getEnv()), // st和production都上报正式环境
                },
              },
            });
        }}
      />
      <Script src="//lx.meituan.net/lx.5.min.js" async />
      <Script
        src="//s3plus.meituan.net/v1/mss_28a77f134e5b4abf876b4ff035f4107f/iconfont/project/1008/0.0.5/meituan-office-website.js"
        defer
      />
    </>
  );
};

export default appWithTranslation(MyApp);
