import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Cookies, { CookieAttributes } from 'js-cookie';
import classNames from 'classnames';

import { getLSItem, setLSItem } from '../../util/storeage';
import styles from './CookieConsent.module.scss';

const COOKIE_CONSENT_NAME = 'cookie_consent';
const EXPIRES_DAYS = 365;

const getCookieConsentValue = (name: string) => {
  const cookieValue = Cookies.get(name);
  const lsValue = getLSItem(name, EXPIRES_DAYS);

  return cookieValue || lsValue;
};

const setCookieConsentValue = (name: string, accept: boolean) => {
  const value = `${accept}`;
  const options: CookieAttributes = {
    expires: EXPIRES_DAYS,
    sameSite: 'Strict',
  };
  setLSItem(name, value);
  Cookies.set(name, value, options);
};

function CookieConsent() {
  const trans = useTranslation('cookie');
  const { t } = trans;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const val = getCookieConsentValue(COOKIE_CONSENT_NAME);
    setVisible(!val);
  }, []);

  /* 预览模式不展示cookie */
  const { query, isReady } = useRouter();
  useEffect(() => {
    if (isReady) {
      if (query?.preview) {
        setVisible(false);
      }
    }
  }, [isReady]);

  const handleAccept = () => {
    setCookieConsentValue(COOKIE_CONSENT_NAME, true);
    setVisible(false);
  };

  const handleReject = () => {
    setCookieConsentValue(COOKIE_CONSENT_NAME, false);
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className={styles['cookie-container']}>
      <div className="px-5 lg:m-auto lg:max-w-screen-xl">
        <div className={styles['cookie-title']}>{t('cookieTitle')}</div>
        <div className={styles['cookie-content']}>
          <div>{t('cookieWelContent')}</div>
          <div>
            <span>{t('cookieContent')}</span>
            <a
              className={styles['light-text']}
              href="https://rules-center.meituan.com/rules-detail/2"
              target="_blank"
              rel="noreferrer"
            >
              {t('cookieSeeText')}
            </a>
            <span>{t('cookieMoreText')}</span>
          </div>
        </div>
        <div className={styles['cookie-btn-ground']}>
          <div className={classNames(styles['cookie-btn'], styles['cookie-btn-accept'])} onClick={handleAccept}>
            {t('cookieAcceptText')}
          </div>
          <div className={classNames(styles['cookie-btn'], styles['cookie-btn-reject'])} onClick={handleReject}>
            {t('cookieRejectText')}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookieConsent;
