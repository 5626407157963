import { Env } from './type';
import { getEnv } from './env';

type LogLevel = 'info' | 'warn' | 'debug' | 'error' | 'fatal';

class Logger {
  env: Env = Env.production;

  constructor(opts: Record<string, any>) {
    this.env = opts.env;
  }

  private _write(log: string | object, level: LogLevel) {
    const isBrowser = typeof window !== 'undefined';
    let logger;
    if (isBrowser) {
      switch (level) {
        case 'info':
          logger = console.log;
          break;
        case 'debug':
          logger = console.debug;
          break;
        case 'error':
        case 'fatal':
          logger = console.error;
          break;
        case 'warn':
          logger = console.warn;
          break;
        default:
          logger = console.log;
      }
    } else {
      const loggerCenter = (global as any).logger || console;
      switch (level) {
        case 'info':
          logger = loggerCenter.info || loggerCenter.log;
          break;
        case 'debug':
          logger = loggerCenter.debug;
          break;
        case 'error':
          logger = loggerCenter.error;
          break;
        case 'fatal':
          logger = loggerCenter.fatal || loggerCenter.error;
          break;
        case 'warn':
          logger = loggerCenter.warn;
          break;
        default:
          logger = loggerCenter.info || loggerCenter.log;
      }
    }
    //  info 和 debug 日志不需要在正式环境输出，以提升性能。
    if (this.env === Env.production && ['info', 'debug'].includes(level)) {
      return;
    }
    /**
     * 1. 不是正式环境，所有日志都需要输出；
     * 2. 正式环境的错误日志都需要输出；
     * */
    if (this.env !== Env.production || (this.env === Env.production && ['error', 'fatal'].includes(level))) {
      const logContent = {
        body: log,
        level,
        log_type: 'biz', // 业务日志需要打一个标识
      };
      logger(logContent);
    }
  }

  info(log: any) {
    this._write(log, 'info');
  }

  warn(log: any) {
    this._write(log, 'warn');
  }

  debug(log: any) {
    this._write(log, 'debug');
  }

  error(log: any) {
    this._write(log, 'error');
  }

  fatal(log: any) {
    this._write(log, 'fatal');
  }
}

export const logger = new Logger({ env: getEnv() });
