import classNames from 'classnames';
import React, { useState } from 'react';
// import Link from 'next/link';
import { Link } from '../../../Link';
import { SubMNav } from './SubMNav';
import style from './mnav.module.scss';
import { NavItemType } from '../../type';
import { Icon } from '../../../SvgIcon';

interface Props {
  items?: Array<NavItemType>;
  toggle: boolean;
  onToggle(toggleable: boolean): void;
  onClose(): void;
}
/**
 * 移动端弹窗导航
 * @param props
 * @returns
 */
export const MNav = (props: Props) => {
  const { items, onToggle, toggle, onClose } = props;
  // 默认选中组件
  const [current, setCurrent] = useState<string>();
  // 展开一个菜单
  const onClick = (key: string) => {
    setCurrent(key);
    onToggle(true);
  };
  // 关闭所有菜单
  const onCancel = () => {
    setCurrent('');
    onToggle(false);
  };

  return (
    <ul
      className={classNames('xl:hidden', {
        [`${style['mnav-ul']}`]: true,
        [`${style['mnav-ul-toggle-open']}`]: toggle,
      })}
    >
      {(items || []).map(({ hasChild, childData, title, key, path, h5Path }) => {
        const itemCls = classNames(style['mnav-item-li'], {
          [`${style['toggle-open']}`]: key === current,
        });
        return (
          <li key={key} className={itemCls}>
            <Link href={h5Path || path} legacyBehavior scroll={false} prefetch={false}>
              <a className={style['mnav-item-link-a']} onClick={onClose}>
                <span>{title}</span>
                {hasChild && (
                  <span
                    className={style['mnav-item-arrow']}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      onClick(key);
                    }}
                  >
                    <Icon name="arrow" width={18} height={18} />
                  </span>
                )}
              </a>
            </Link>
            {/* 绘制子菜单 */}
            {hasChild && <SubMNav childData={childData} onCancel={onCancel} title={title} onClose={onClose} />}
          </li>
        );
      })}
    </ul>
  );
};
