import React from 'react';
import { useTranslation } from 'next-i18next';
import { Header, Footer } from '../Common';
import { useHeaderDataKey, useFooterDataKey } from './useDataKey';
import { useScreenSize } from '../ScreenSize';
import { LxContainer } from './lxContext';
// import { pageView } from '../../util/lx';

interface Props {
  children: React.ReactNode;
  lxCustom?: Record<string, any>;
}

export const Layout = (props: Props) => {
  const { t } = useTranslation('common');
  // 处理顶部菜单
  const headerNavArrs = t('headerNavArrs', { returnObjects: true }) as any;
  // 处理关注我们
  const followMeLang = t('followMe', { returnObjects: true }) as any;

  const footerMenus = t('footerMenus', { returnObjects: true }) as any;
  const copyright = t('footerAffirm', { returnObjects: true }) as any;

  const { children, lxCustom } = props;

  const headerMenusArrs = useHeaderDataKey(headerNavArrs);
  const footerMenusArrs = useFooterDataKey(footerMenus);

  const { size, screen } = useScreenSize();

  // useEffect(() => {
  //   if (size.width === 0) {
  //     return;
  //   }
  //   pageView({
  //     cid: lxCustom?.cid,
  //     custom: {
  //       ...lxCustom,
  //       screen,
  //     },
  //   });
  // }, [lxCustom, size, screen]);

  return (
    <LxContainer lxCustom={lxCustom} size={size} screen={screen}>
      <Header items={headerMenusArrs} followMe={followMeLang} />
      <div>{children}</div>
      <Footer items={footerMenusArrs} followMe={followMeLang} copyright={copyright} />
    </LxContainer>
  );
};
