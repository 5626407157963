import React from 'react';

export enum DeviceType {
  PC = 'pc',
  MOBILE = 'mobile',
}

export interface LinkItem {
  title: string;
  path: string; // 默认PC的path
  h5Path?: string; // 默认移动端Path
  out?: boolean;
  icon?: React.ReactNode;
  QRCode?: string; // 页面的二维码
}

export interface NavItemType extends LinkItem {
  key: string;
  hasChild?: boolean;
  childData?: SubNavPanel;
}

export interface FooterSingeMenuType {
  title: string;
  items: Array<LinkItem>;
}
export type FooterMenuType = Array<{ title: string; items: Array<LinkItem> }>;

export interface SubNavMenu {
  title: string;
  items: Array<{ title: string; key: string; path: string; isDonation?: boolean; out?: boolean }>;
}
export interface SubNavPanel {
  navArrs: Array<SubNavMenu>;
  bottom?: { title: string; path: string };
}
