import React, { useEffect, useRef } from 'react';
import tippy, { roundArrow } from 'tippy.js';
import classNames from 'classnames';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';

interface Props {
  children: React.ReactNode;
  placement: 'left' | 'top' | 'bottom' | 'right' | 'top-start';
  content: React.ReactNode;
  className?: string;
  theme?: string;
  trigger?: string;
  triggerTarget?: any;
  offset?: [number, number];
}
export const Tooltips = (props: Props) => {
  const {
    children,
    placement,
    content,
    className,
    theme = 'meituan',
    offset,
    trigger = 'mouseenter focus',
    triggerTarget = null,
  } = props;
  const domRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!domRef.current) return () => {};
    const instance = tippy(domRef.current, {
      content: wrapperRef?.current || '',
      placement,
      offset,
      arrow: roundArrow,
      theme,
      trigger,
      triggerTarget: triggerTarget?.current || null,
      zIndex: 10001,
    });
    // instance.show();
    return () => {
      instance.destroy();
    };
  }, [domRef, placement, offset, trigger, triggerTarget, theme]);

  return (
    <>
      <div ref={domRef} className={classNames(className)}>
        {children}
      </div>
      <div className="hidden">
        <div ref={wrapperRef}>{content}</div>
      </div>
    </>
  );
};
