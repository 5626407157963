import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { NavToggle } from '../Toggle';
import { NavLogo } from '../Logo/NavLogo';
import { NavLangSelector } from '../Lang/index';
import { NavMenu } from '../Nav/NavMenu';
import { MNavBar } from '../MNav/MNavBar';
import { DeviceType, NavItemType } from '../../type';
import { useScreenSize } from '../../../ScreenSize';
import { useTheme } from './useTheme';
import { LogoModal } from '../Lang/LogoModal';
import { useLogoLink } from '../hooks/useLogoLink';

import style from './Header.module.scss';

interface Props {
  items: Array<NavItemType>;
  followMe: Record<string, any>;
}
/**
 * 业务Header组件菜单
 * @returns
 */
export function Header(props: Props) {
  const [moblieMenuVisible, setMoblieMenuVisible] = useState(false);
  const { size } = useScreenSize();
  const { fixed, theme } = useTheme();
  const { items, followMe } = props;
  // 记录上一次theme的值
  const [weiChatVisibe, setWeiChatVisibe] = useState(false);
  const [shipinghaoVisible, setShipinghaoVisible] = useState(false);
  // 打开移动端菜单
  const onOpenMoblieMenu = useCallback(() => {
    setMoblieMenuVisible(true);
  }, []);
  // 关闭移动端菜单
  const onCloseMoblieMenu = useCallback(() => {
    setMoblieMenuVisible(false);
  }, []);

  // 区分设备类型
  const dType = useMemo(() => {
    if (size.width === 0) {
      return DeviceType.PC;
    }
    if (size.width < 1280) {
      return DeviceType.MOBILE;
    }
    return DeviceType.PC;
  }, [size]);

  const headerCls = classNames(style.header, {
    [`with-filter-background`]: fixed,
    [`header-dark`]: !fixed && theme === 'dark',
    [`header-light`]: !fixed && theme === 'light',
  });

  const onToggleType = (type: string) => {
    if (type === 'weiChat') {
      setWeiChatVisibe(true);
    } else {
      setShipinghaoVisible(true);
    }
    setMoblieMenuVisible(false);
  };

  const jumpLink = useLogoLink();

  return (
    <div className={headerCls}>
      <div className={style.headerBg} />
      {/* 移动端的切换按钮 */}
      <NavToggle onClick={onOpenMoblieMenu} theme={theme} />
      <NavLogo type={dType} theme={theme} fixed={fixed} href={jumpLink} />
      {/*  PC端导航 */}
      <NavMenu items={items} />
      {/* PC端导航结束 */}
      <NavLangSelector followMe={followMe} />
      <MNavBar
        items={items}
        visible={moblieMenuVisible}
        followMe={followMe}
        onClose={onCloseMoblieMenu}
        onClick={onToggleType}
      />
      <LogoModal
        logoSrc={followMe.weiChatIcon}
        visible={weiChatVisibe}
        title={followMe.modal.weiChatTitle}
        tipsTitle={followMe.modal.tipsTitle}
        onClose={() => setWeiChatVisibe(false)}
      />
      <LogoModal
        logoSrc={followMe.shipinghaoIcon}
        visible={shipinghaoVisible}
        title={followMe.modal.shipinghaoTitle}
        tipsTitle={followMe.modal.tipsTitle}
        onClose={() => setShipinghaoVisible(false)}
      />
    </div>
  );
}
