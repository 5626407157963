import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import KNB from '@dp/knb/bundle';
import Head from 'next/head';
import { Loader } from './loader';
import { logger } from '../../util/logger';

export interface IShareContent {
  title: string;
  desc: string;
  image: string;
}

export const ShareContext = React.createContext<{ configShare: (content: IShareContent) => void; ready: boolean }>({
  configShare: () => {},
  ready: false,
});

const DEFAULT_TITLE = '美团';
const DEFAULT_DESC = '帮大家吃得更好，生活更好';
const DEFAULT_IMAGE = 'https://p0.meituan.net/smartvenus/519f64f1d51d8470ecad1f9b5fce83591696.png';

interface IProps {
  children: React.ReactElement;
  /* 服务端生成的分享配置：在Chrome App中自定义分享内容，需要在服务端就完成正确的meta渲染 */
  shareConfig?: IShareContent;
  [key: string]: any;
}
/**
 * @description 分享；
 */
function Share(props: IProps) {
  const {
    children,
    shareConfig = {
      title: DEFAULT_TITLE,
      desc: DEFAULT_DESC,
      image: DEFAULT_IMAGE,
    },
  } = props;
  const initRef = useRef<boolean>(false);
  const WxRef = useRef<any>(null);
  const [shareContent, setShareContent] = useState<IShareContent>(shareConfig);
  const [ready, setReady] = useState(false); // 记录分享SDK是否已经ready
  // const router = useRouter();
  // const { pathname } = router;
  // const { ns = [] } = i18n || {};
  // const currNs: string = useMemo(() => {
  //   for (let i = 0; i < ns?.length; i++) {
  //     if (pathname.includes(ns[i])) {
  //       return ns[i];
  //     }
  //   }
  //   return 'common';
  // }, [ns, pathname]);
  // const { t } = useTranslation(currNs);

  const realShareContent = useMemo(
    () => ({
      title: shareContent?.title || DEFAULT_TITLE,
      desc: shareContent?.desc || DEFAULT_DESC,
      // 分享图标，建议分享图片尺寸 100X100~120X120, 大小不超过32K
      // https://p0.meituan.net/smartvenus/11688a8e251fd9bd9ffe9cbece035f462394.png
      image: shareContent?.image || DEFAULT_IMAGE,
    }),
    [shareContent],
  );

  useEffect(() => {
    if (!Loader.isWeixin()) return;
    const init = async () => {
      const loader = await import('@dp/weixin-js-sdk-loader');
      loader.use((wx) => {
        WxRef.current = wx;
      });
    };
    init();
  }, []);

  // useEffect(() => {
  //   /** TODO: 可以通过后端接口获取 */
  //   const share: IShareContent = t('share', { returnObjects: true });
  //   setShareContent(share);
  // }, [currNs, t]);

  useEffect(() => {
    KNB.ready(() => {
      KNB.configShare({
        ...realShareContent,
        // 只出现在分享到朋友圈，如果未提供content，则读取【title】+desc
        url: window.location.href,
        // 分享渠道 v1
        channel: [KNB.share.WECHAT_FRIENDS, KNB.share.WECHAT_TIMELINE],
        handle() {
          // alert('分享按钮被点击了');
          logger.info('分享成功');
        },
      });
      initRef.current = true;
      setReady(true);
    });
    // 如果是微信空间
    if (WxRef.current) {
      const wx = WxRef.current;
      const wxShareData = {
        ...realShareContent,
        // 只出现在分享到朋友圈，如果未提供content，则读取【title】+desc
        link: window.location.href,
        imgUrl: realShareContent.image,
      };
      wx.ready(() => {
        // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
        wx.updateAppMessageShareData({
          ...wxShareData,
          success() {
            // 设置成功
            logger.info('分享给朋友配置成功');
          },
        });
        wx.updateTimelineShareData({
          ...wxShareData,
          success() {
            // 设置成功
            logger.info('分享朋友圈配置成功');
          },
        });
        wx.onMenuShareWeibo({
          ...wxShareData,
          success() {
            // 用户确认分享后执行的回调函数
            logger.info('分享到腾讯微博成功');
          },
          cancel() {
            // 用户取消分享后执行的回调函数
            logger.info('取消分享到腾讯微博');
          },
        });
      });
    }
  }, [realShareContent, WxRef.current]);

  useEffect(() => {
    setTimeout(() => {
      if (!initRef.current) {
        setReady(true);
        initRef.current = true;
      }
    }, 3000);
  }, []);

  const configShare = useCallback((content: IShareContent) => {
    setShareContent(content);
  }, []);

  const contextVal = useMemo(() => ({ configShare, ready }), [configShare, ready]);
  const dxShareContent = useMemo(
    () => `
    <dx_title>${realShareContent?.title}</dx_title>
    <dx_image>${realShareContent?.image}</dx_image>
    <dx_content>${realShareContent?.desc}</dx_content>
  `,
    [realShareContent],
  );
  // https://ogp.me/ 浏览器原生分享能力定义的开放协议。
  return (
    <>
      <Head>
        <meta property="og:title" content={realShareContent?.title} />
        <meta property="og:description" content={realShareContent?.desc} />
        <meta property="og:image" content={realShareContent?.image} />
      </Head>
      <div style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: dxShareContent }} />
      <ShareContext.Provider value={contextVal}>{children}</ShareContext.Provider>
    </>
  );
}

export default Share;
