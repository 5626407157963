import React from 'react';
import classNames from 'classnames';
import style from './index.module.scss';

interface Props {
  onClick?(): void;
  theme?: string;
}

export const NavToggle = (props: Props) => {
  const { onClick, theme = 'light' } = props;
  const handleClick = () => {
    onClick?.();
  };

  const itemCls = classNames(style.item, {
    [`${style.dark}`]: theme === 'dark',
    [`${style.light}`]: theme === 'light',
  });

  return (
    <div className="absolute pl-6 xl:hidden">
      <div className={style.toggle} onClick={handleClick}>
        <span className={itemCls} />
        <span className={itemCls} />
        <span className={itemCls} />
      </div>
    </div>
  );
};
