import React from 'react';

/**
 * @description 遵循tailwindcss的默认breakpoints定义
 * @doc https://tailwindcss.com/docs/responsive-design#using-custom-breakpoints
 * sm	640px	@media (min-width: 640px) { ... }
 * md	768px	@media (min-width: 768px) { ... }
 * lg	1024px	@media (min-width: 1024px) { ... }
 * xl	1280px	@media (min-width: 1280px) { ... }
 * 2xl	1536px	@media (min-width: 1536px) { ... }
 */

export enum BreakPointSize {
  sm = 640,
  md = 769,
  lg = 1024,
  xl = 1280,
  '2xl' = 1536,
}

export enum BreakPoint {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl',
}

export interface IScreenSize {
  size: {
    height: number;
    width: number;
  };
  screen: BreakPoint;
  isMobile: boolean;
}

export const ScreenSizeProvider = React.createContext<IScreenSize>({
  size: { height: 0, width: 0 },
  screen: BreakPoint.sm,
  isMobile: true,
});
