import React, { useRef } from 'react';
import classNames from 'classnames';
// import Link from 'next/link';
import { Link } from '../../../Link';
import { SubNav } from './SubNav';
import styles from './NavPanel.module.scss';
import { Transition } from '../Transition';

interface Props {
  visible: boolean; // 是否展示
  navArrs: Array<any>;
  bottom?: {
    path: string;
    title: string;
  };
}
export const NavPanel = (props: Props) => {
  const { navArrs, bottom, visible } = props;
  const divLeftRef = useRef(0);
  const hasFooter = !!bottom;

  const panelCls = classNames(styles['nav-panel'], {
    [`${styles['toggle-open']}`]: visible,
  });

  const panelBgCls = classNames(styles['nav-panel-bg']);
  const panelContentCls = classNames(styles['nav-panel-content']);

  const onEnterStart = () => {
    return { height: 0 };
  };
  const onEnter = (elm: HTMLElement) => {
    const rect = elm.getBoundingClientRect();
    const divleft = window.innerWidth - rect.left;
    const width = elm.scrollWidth;
    let translateLeft = 0;
    if (divleft < width + 20) {
      translateLeft = width - divleft + 20;
    }
    if (translateLeft) {
      divLeftRef.current = translateLeft;
    }
    return { height: elm.scrollHeight, transform: `translateX(-${translateLeft}px)` };
  };
  const onEnterEnd = () => {
    return { transform: `translateX(-${divLeftRef.current}px)` };
  };
  const onLeaveStart = (elm: HTMLElement) => ({
    height: elm.offsetHeight,
    transform: `translateX(-${divLeftRef.current}px)`,
  });
  const onLeave = () => {
    divLeftRef.current = 0;
    return { height: 0 };
  };

  const onlyOneNav = navArrs?.length === 1;

  // console.log(navArrs, navArrs.length);

  return (
    <Transition
      visible={visible}
      enable
      name="dropdown"
      enterEndCls={styles.block}
      leaveEndCls={styles.none}
      onEnter={onEnter}
      onEnterStart={onEnterStart}
      onEnterEnd={onEnterEnd}
      onLeave={onLeave}
      onLeaveStart={onLeaveStart}
    >
      {({ className, style }, ref) => {
        return (
          <div className={classNames(panelCls, className)} style={style} ref={ref}>
            <div className={panelBgCls}>
              <div className={panelContentCls}>
                <div className={`flex ${onlyOneNav ? 'p-[4px]' : `pl-10 pr-10 pt-10`}`}>
                  {navArrs.map((_, index) => (
                    <SubNav onlyOneNav={onlyOneNav} {..._} key={index} />
                  ))}
                </div>
                {hasFooter && (
                  <div className="mx-10 border-t border-t-gray-10 py-7 text-center align-middle text-gray-90">
                    <Link href={bottom?.path} legacyBehavior prefetch={false}>
                      <a className="hover:underline" draggable="false">
                        {bottom?.title}
                      </a>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </Transition>
  );
};
