import React, { useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { NavItem } from './NavItem';
import { NavItemType } from '../../type';

interface Props {
  items?: Array<NavItemType>;
}

export const NavMenu = (props: Props) => {
  const { items } = props;
  // 当前选中的Key
  // const [selectedKey, setSelectedKey] = useState('');
  // 当前鼠标hover上的Key
  const [selectPanelKey, setPanelKey] = useState('');

  const router = useRouter();
  const { pathname } = router;

  const currentKey = useMemo(() => {
    let current = '';
    items?.forEach((item) => {
      // const reg = new RegExp(item.path.replace(/^\//, ''), 'g');
      // if (reg.test(pathname)) {
      //   current = item.key;
      //   console.log(current, item);
      // }
      if (item.path === pathname) {
        current = item.key;
      } else if (pathname.includes('/csr') && item.path === '/csr') {
        // csr二级页面focus到企业社会责任特殊处理
        current = item.key;
      }
    });
    return current;
  }, [items, pathname]);

  // 切换hover状态
  const onToggle = (key: string) => {
    // console.log(key);
    setPanelKey(key);
  };

  return (
    <nav className="hidden xl:flex xl:h-20 xl:items-center xl:justify-center xl:bg-transparent">
      <ul className="hidden xl:flex">
        {(items || []).map((_) => {
          const isHover = selectPanelKey === _.key;
          const { key, ...other } = _;
          // 如果没有子节点
          return (
            <NavItem {...other} key={key} uid={key} isHover={isHover} selectKey={currentKey} onToggle={onToggle} />
          );
        })}
      </ul>
    </nav>
  );
};
