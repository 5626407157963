import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

interface Props {
  toggle: boolean;
  children: React.ReactNode;
}

export const DropDownPanel = (props: Props) => {
  const { toggle, children } = props;
  return (
    <div
      className={classNames(styles.dropMenu, {
        [`${styles.dropDownCurrent}`]: toggle,
      })}
    >
      <div className={classNames(styles.dropMenuInner)}>{children}</div>
    </div>
  );
};
