import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
// import Link from 'next/link';
import { Link } from '../../../Link';
import { DeviceType } from '../../type';
import { Meituan, MeituanEn } from '../Icon';
import style from './index.module.scss';

interface Props {
  type?: DeviceType;
  href?: string;
  theme?: any;
  fixed: boolean;
}

export const NavLogo = (props: Props) => {
  const router = useRouter();
  const { locale = 'zh-CN' } = router;
  const { type, href = '/', theme, fixed } = props;

  const fillColor = useMemo(() => {
    if (fixed) return '#111925';
    if (theme === 'dark') {
      return '#ffffff';
    }
    return '#111925';
  }, [fixed, theme]);

  const renderIcon = (iconProps: any) => {
    if (locale === 'en-US') {
      return <MeituanEn {...iconProps} />;
    }
    return <Meituan {...iconProps} />;
  };

  return (
    <div className={style.logoWrapper}>
      <Link href={href} legacyBehavior scroll={false} prefetch={false}>
        <a aria-label="美团官网" className={style.logoLink}>
          {renderIcon({ fillColor, ratio: type === DeviceType.PC ? 1 : 0.6 })}
        </a>
      </Link>
    </div>
  );
};
