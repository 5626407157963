import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { DropDown } from './MenuDropDown';
import { DropDownPanel } from './MenuDropDownPanel';
import { OutLink } from './OutLink';

import { items } from '../constants';
import { LangType } from '../LangSelector';

export const EnLang = () => {
  const { locale = 'zh-CN' } = useRouter();

  const item = useMemo(() => {
    return items.find((langItem) => langItem.key === locale);
  }, [locale]);

  return (
    <DropDown
      item={item as LangType}
      renderChild={(toggle, setToggle) => {
        return (
          <DropDownPanel toggle={toggle}>
            <OutLink
              title="中文版本"
              href="/"
              onClick={() => {
                setToggle(false);
              }}
            />
          </DropDownPanel>
        );
      }}
    />
  );
};
