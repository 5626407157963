import { AppMeituan, AppDianping, AppWaimai } from '../Icon';

export const getIcon = (item: any, size = 20) => {
  const { app } = item;
  if (app === 'meituan') {
    return <AppMeituan width={size} height={size} />;
  }
  if (app === 'dianping') {
    return <AppDianping width={size} height={size} />;
  }
  if (app === 'waimai') {
    return <AppWaimai width={size} height={size} />;
  }
  return false;
};
