import React from 'react';
// import Link from 'next/link';
import { Link } from '../../../Link';
import styles from './subNav.module.scss';
import { DonationIcon } from './Donation';
import LinkOutIcon from '../../../LinkOutIcon';

interface Props {
  title: string;
  items: Array<any>;
  onlyOneNav?: boolean;
}

export const SubNav = (props: Props) => {
  const { title, items, onlyOneNav } = props;
  const renderItem = (item: Record<string, any>) => {
    if (item.isDonation) {
      return <DonationIcon icon={item.path} title={item.title} />;
    }
    if (item.out) {
      return (
        <a
          href={item.path}
          draggable="false"
          className={`flex items-center justify-between text-gray-90 ${onlyOneNav ? false : 'hover:underline'}`}
          target="_blank"
          rel="noreferrer"
        >
          <span>{item.title}</span>
          {item.hasIcon && <LinkOutIcon />}
        </a>
      );
    }
    return (
      <Link href={item.path} legacyBehavior scroll={false} prefetch={false}>
        <a className={`text-gray-90 ${onlyOneNav ? false : 'hover:underline'}`} draggable="false">
          {item.title}
        </a>
      </Link>
    );
  };

  return (
    <div className={`${styles['subnav-wrap']} min-w-[112px]`}>
      {title && <h6 className={styles['subnav-menu-header']}>{title}</h6>}
      <ul className={`${onlyOneNav ? false : 'mb-1'}`}>
        {(items || []).map((item, index) => (
          <li
            className={`${onlyOneNav ? `p-[8px] ${styles['subnav-menu-li']}` : 'mb-7'} whitespace-nowrap`}
            key={index}
          >
            {renderItem(item)}
          </li>
        ))}
      </ul>
    </div>
  );
};
