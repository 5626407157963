import React from 'react';

interface Props {
  width?: number;
  height?: number;
  ratio?: number;
  className?: string;

  fillColor?: string; // 填充颜色
}

export const MeituanEn = (props: Props) => {
  const { width = 160, height = 40, className, ratio = 1, fillColor = '#000000' } = props;
  const cWidth = Math.ceil(width * ratio);
  const cHeight = Math.ceil(height * ratio);

  return (
    <svg width={cWidth} height={cHeight} className={className} viewBox="0 0 160 40">
      <path
        d="M11.5375352,0 L28.4627401,0 C32.4743836,0 33.9294095,0.417842824 35.3959963,1.20233034 C36.8625832,1.98626734 38.0137217,3.13739955 38.7982136,4.60397832 C39.5827054,6.0711076 40,7.52557498 40,11.5377469 L40,28.4623081 C40,32.4744801 39.5827054,33.9289475 38.7982136,35.3960767 C38.0137217,36.8626555 36.8625832,38.0137877 35.3959963,38.7977247 C33.9294095,39.5822122 32.4743836,40.0000551 28.4627401,40.0000551 L11.5375352,40.0000551 C7.52589167,40.0000551 6.07086576,39.5822122 4.60427892,38.7977247 C3.13769208,38.0137877 1.98655353,36.8626555 1.2020617,35.3960767 C0.417569865,33.9289475 -0.000275260293,32.4744801 -0.000275260293,28.4623081 L-0.000275260293,11.5377469 C-0.000275260293,7.52557498 0.417569865,6.0711076 1.2020617,4.60397832 C1.98655353,3.13739955 3.13769208,1.98626734 4.60427892,1.20233034 C6.07086576,0.417842824 7.52589167,0 11.5375352,0"
        fill="#FFD100"
      />
      <path
        d="M12.7638061,22.6264036 C14.0439616,24.1897434 16.4233396,25.1870559 19,25.3869602 L18.1138643,26.9497478 C15.7344863,26.6670103 12.9932423,25.5366123 11.4673013,23.5408828 C10.0558058,25.5366123 7.36415492,26.7503958 4,27 L4.52535969,25.3201414 C6.80664148,25.2870081 9.16967011,24.0897913 10.1871458,22.6264036 L4.21363174,22.6264036 L4.21363174,21.1795825 L10.5811655,21.1795825 L10.5811655,19.816147 L4.57440779,19.816147 L4.57440779,18.3858926 L10.5811655,18.3858926 L10.5811655,17.271509 L4.57440779,17.271509 L4.57440779,15.8412546 L10.5811655,15.8412546 L10.5811655,14.6777234 L4.21363174,14.6777234 L4.21363174,13.2309023 L6.79029211,13.2309023 L6.64260282,12 L8.43122366,12 C8.49662113,12.3992563 8.54621421,12.8150793 8.61161168,13.2309023 L14.3393402,13.2309023 C14.4210871,12.8150793 14.4706801,12.3992563 14.5033789,12 L16.3252434,12 C16.2919997,12.3992563 16.2429516,12.8150793 16.1770092,13.2309023 L18.7536695,13.2309023 L18.7536695,14.6777234 L12.3370876,14.6777234 L12.3370876,15.8412546 L18.3438454,15.8412546 L18.3438454,17.271509 L12.3370876,17.271509 L12.3370876,18.3858926 L18.3438454,18.3858926 L18.3438454,19.816147 L12.3370876,19.816147 L12.3370876,21.1795825 L18.7536695,21.1795825 L18.7536695,22.6264036 L12.7638061,22.6264036 Z"
        fill="#000000"
      />
      <path
        d="M23.575173,17.7546066 L23.575173,16.408913 L29.2635018,16.408913 L29.2635018,15.191938 L30.9418564,15.191938 L30.9418564,16.408913 L32.5390266,16.408913 L32.5390266,17.7546066 L30.9418564,17.7546066 L30.9418564,21.550739 C30.9418564,22.8480301 30.7789461,23.8091638 30.4206518,24.8022112 L28.5950825,24.8022112 C29.1493022,23.6490635 29.2635018,22.8320732 29.2635018,21.5986095 L29.2635018,17.7546066 L27.7312792,17.7546066 C27.5028801,20.2369591 26.5248773,23.3123741 25.4981637,24.8022112 L23.6401206,24.8022112 C24.6343604,23.4246039 25.7752735,20.7337487 26.0523833,17.7546066 L23.575173,17.7546066 Z M22.7108285,25.5745222 L33.2886303,25.5745222 L33.2886303,14.4254778 L22.7108285,14.4254778 L22.7108285,25.5745222 Z M35,13.8808176 L35,27 L21.912514,27 C21.3258205,27 21,26.6797994 21,26.1191824 L21,13 L34.0712491,13 C34.6579426,13 35,13.3202006 35,13.8808176 L35,13.8808176 Z"
        fill="#000000"
      />
      <path
        d="M70.4039621,8 C71.6576425,8 72,8.34136715 72,9.59395121 L72,30 L68.8649837,30 L68.8649837,16.8511237 C68.8649837,14.9445692 68.8649837,13.4076029 69.0073609,11.9276217 L68.9508448,11.9276217 L62.8237328,24.7915731 L60.1734512,24.7915731 C59.4039621,24.7915731 59.062148,24.5923971 58.7197905,23.8808989 L53.0491552,11.9276217 L52.9920956,11.9276217 C53.1062148,13.4076029 53.1344729,14.9445692 53.1344729,16.8511237 L53.1344729,30 L50,30 L50,8 L53.1915325,8 C53.9892797,8 54.2745776,8.17095493 54.6163917,8.85368922 L61,22.0025656 L67.81074,8 L70.4039621,8 Z"
        fill={fillColor}
      />
      <path
        d="M87.0024479,21.3019196 C87.0024479,20.9352645 87.0024479,20.4842035 86.946392,20.0616363 C86.6383512,17.7493438 85.6304118,16.5655103 83.5867721,16.5655103 C81.4032595,16.5655103 79.5555487,17.8622434 79.1636909,21.3019196 L87.0024479,21.3019196 Z M89.997971,21.6121249 C89.9702099,23.3034692 89.4379454,23.7545302 87.4781227,23.7545302 L79.1636909,23.7545302 C79.4156758,26.940451 80.8715288,28.4344897 83.838757,28.4344897 C86.2187324,28.4344897 87.7301075,28.0398786 89.4379454,27.1662503 L88.9622706,29.9570222 C87.7861634,30.5209829 86.0783256,31 83.838757,31 C78.6597213,31 76,28.293634 76,22.9373518 C76,18.6799595 77.9598227,14 83.6150671,14 C87.5624735,14 90.081788,16.9886152 89.997971,21.6121249 L89.997971,21.6121249 Z"
        fill={fillColor}
      />
      <path
        d="M93.12702,31 L95.87298,31 L95.87298,14.2203166 L93.12702,14.2203166 L93.12702,31 Z M96,11.5004797 L93,11.5004797 L93,8 L95.135585,8 C95.6950549,8 96,8.31832574 96,8.95442552 L96,11.5004797 Z"
        fill={fillColor}
      />
      <path
        d="M110,27.701723 L109.410252,30.5410855 C108.230221,30.8566918 107.191065,31 106.067813,31 C103.454925,31 102.387916,29.4799483 102.387916,25.8370752 L102.387916,16.5439198 L100,16.5439198 L100,14.0196166 L102.387916,14.0196166 L102.387916,9 L105.421287,9 L105.421287,14.0196166 L109.578981,14.0196166 L109.07333,16.5439198 L105.421287,16.5439198 L105.421287,25.8370752 C105.421287,27.5294249 105.842841,28.1890803 106.910386,28.1890803 C107.416037,28.1890803 108.567679,28.1032048 110,27.701723"
        fill={fillColor}
      />
      <path
        d="M126,29.3917217 C124.372198,30.1671123 121.97452,31 118.92606,31 C113.331479,31 112,28.1284265 112,24.3089708 L112,14 L115.196339,14 L115.196339,23.849541 C115.196339,26.4341762 115.255604,28.3578676 118.92606,28.3578676 C120.9388,28.3578676 121.915256,28.1284265 122.803096,27.7549686 L122.803096,14 L126,14 L126,29.3917217 Z"
        fill={fillColor}
      />
      <path
        d="M139.108196,27.81418 L139.108196,23.0214724 C138.013262,22.9085763 136.721293,22.8521283 135.738797,22.9085763 C133.239992,23.0779204 132.11668,23.8391626 132.11668,25.6997976 C132.11668,27.5604326 133.015115,28.4345709 135.261738,28.4345709 C136.889951,28.4345709 138.237603,28.2087787 139.108196,27.81418 M142,19.0744102 L142,29.4210676 C140.539909,30.2387578 137.648105,31 134.812521,31 C131.302842,31 129,29.082917 129,25.8406489 C129,21.5554361 132.846993,20.6818354 135.57014,20.5409841 C136.553171,20.4845361 137.788386,20.5409841 139.108196,20.6533426 L139.108196,19.102903 C139.108196,17.4960154 137.760544,16.5654291 135.373641,16.5654291 C133.239992,16.5654291 131.808278,16.9320726 130.319811,17.4116122 L130.825247,14.9020935 C131.920181,14.4795396 133.576771,14 135.57014,14 C139.557414,14 142,15.8891278 142,19.0744102"
        fill={fillColor}
      />
      <path
        d="M146,15.6395521 C147.613356,14.8628356 150.004002,14 153.115357,14 C158.703602,14 160,16.9050077 160,20.7309951 L160,31 L156.888645,31 L156.888645,21.1912106 C156.888645,18.6021554 156.830966,16.6461022 153.115357,16.6461022 C151.156478,16.6461022 149.975437,16.9916753 149.111355,17.2791043 L149.111355,31 L146,31 L146,15.6395521 Z"
        fill={fillColor}
      />
    </svg>
  );
};
