/** @format */

import classNames from 'classnames';
import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { useMotionEvent } from './useMotionEvent';

interface Props {
  enable: boolean; // 启动启用动画
  visible: boolean;
  name: string; // 动画的名称
  duration?: number; // 动画执行时间
  onEnterStart?(node: HTMLElement): React.CSSProperties;
  onLeaveStart?(node: HTMLElement): React.CSSProperties;
  onEnter?(node: HTMLElement): React.CSSProperties;
  onLeave?(node: HTMLElement): React.CSSProperties;
  onEnterEnd?(node: HTMLElement): React.CSSProperties;
  onLeaveEnd?(node: HTMLElement): React.CSSProperties;
  className?: string;
  enterEndCls?: string; // 动画显示完后的class
  leaveEndCls?: string; // 动画消失完成后的class
  children(props: any, ref: any): React.ReactElement;
}

enum Mode {
  Idle = 'Idle',
  Enter = 'Enter',
  Leave = 'Leave',
}

enum Step {
  Start = 'Start',
  Doing = 'Doing',
  End = 'End',
}

const getAnimateCls = (mode: Mode, step: Step, name: string, enterEndCls = 'block', leaveEndCls = 'none') => {
  let modeName = '';
  if (mode === Mode.Enter) {
    modeName = `enter`;
  } else if (mode === Mode.Leave) {
    modeName = `leave`;
  }

  let stepCls = '';
  if (step === Step.Start) {
    stepCls = `${name}-${modeName}`;
  } else if (step === Step.Doing) {
    stepCls = `${name}-${modeName}-active ${name}-${modeName}-to`;
  } else if (step === Step.End) {
    switch (modeName) {
      case 'enter':
        stepCls = enterEndCls;
        break;
      case 'leave':
        stepCls = leaveEndCls;
        break;
      default:
        break;
    }
  }
  return stepCls;
};

export const Transition = (props: Props) => {
  const {
    children,
    visible,
    name,
    onEnter,
    onLeave,
    onEnterStart,
    onLeaveStart,
    onEnterEnd,
    onLeaveEnd,
    enterEndCls,
    leaveEndCls,
    enable,
  } = props;
  const nodeRef = useRef<HTMLElement>();
  const visibleRef = useRef<boolean>(false);
  const [animateMode, setAnimateMode] = useState(Mode.Idle);
  const [step, setStep] = useState(Step.End); // 默认是执行结束了
  const [mergeStyle, setMergeStyle] = useState({});

  const startQueue = useCallback(
    (mode: Mode) => {
      if (mode !== Mode.Idle) {
        setAnimateMode(mode);
        const style =
          mode === Mode.Enter ? onEnterStart?.(nodeRef.current as any) : onLeaveStart?.(nodeRef.current as any);
        setMergeStyle(style || {});
        setStep(Step.Start);
        setTimeout(() => {
          const newStyle = mode === Mode.Enter ? onEnter?.(nodeRef.current as any) : onLeave?.(nodeRef.current as any);
          setStep(Step.Doing);
          setMergeStyle(newStyle || {});
        }, 17);
      }
    },
    [onEnter, onLeave, onEnterStart, onLeaveStart],
  );

  useEffect(() => {
    // 禁止动画
    if (!enable) return;
    let nextStatus: Mode = Mode.Idle;
    // 重写状态
    if (!visibleRef.current && visible) {
      nextStatus = Mode.Enter;
    } else if (visibleRef.current && !visible) {
      nextStatus = Mode.Leave;
    }
    visibleRef.current = visible;
    startQueue(nextStatus);
  }, [visible, startQueue, enable]);
  // 默认动画执行结束的回掉
  const transitionEnd = useCallback(() => {
    // 关闭动画，已经执行结束了
    const newStyle =
      animateMode === Mode.Enter ? onEnterEnd?.(nodeRef.current as any) : onLeaveEnd?.(nodeRef.current as any);
    setStep(Step.End);
    setMergeStyle(newStyle || {});
  }, [animateMode, onEnterEnd, onLeaveEnd]);
  // 绑定事件
  const [addEvent, removeEvent] = useMotionEvent(nodeRef, transitionEnd);
  useEffect(() => {
    if (!nodeRef.current) return;
    addEvent(nodeRef.current as HTMLElement);
  }, [nodeRef, addEvent, removeEvent]);
  // 处理动画执行过程中的start doing end的css名字
  const domCls = getAnimateCls(animateMode, step, name, enterEndCls, leaveEndCls);

  const idleCls = useMemo(() => {
    if (animateMode === Mode.Idle) {
      if (visibleRef.current === true) {
        return enterEndCls ?? 'block';
      }
      return leaveEndCls ?? 'none';
    }
    return '';
  }, [visibleRef, animateMode, enterEndCls, leaveEndCls]);

  const outProps = enable
    ? {
        className: classNames(domCls, idleCls),
        style: mergeStyle,
      }
    : {};

  // 如果禁用，不准许变化
  return children(outProps, nodeRef);
};
