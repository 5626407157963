import React from 'react';
import { useRouter } from 'next/router';
// import { LangSelector, LangType } from './LangSelector';
import { LangType } from './LangSelector';
import { Image } from '../../../Image';
import { Lang } from './H5';
import { WeiChat, Weibo, ShiPinHao } from '../Icon';
import { getIcon } from './getAppIcon';

import style from './index.module.scss';

interface Props {
  followMe: Record<string, any>;
  onClick(type: string): void;
  onClose(): void;
}

export const MNavLangSelector = (props: Props) => {
  const router = useRouter();
  const { followMe, onClick, onClose } = props;

  const { locale = 'zh-CN' } = router;
  const handleLocaleChange = (item: LangType) => {
    router.push(router.route, router.asPath, {
      locale: item.key,
    });
    onClose();
  };

  return (
    <div className="mb-5">
      {locale !== 'en-US' ? (
        <>
          <h3 className={style.followMeTitle}>{followMe.navTitle}</h3>
          <ul className={style.followMeIcon}>
            {followMe.app.map((app: any, index: number) => {
              const Icon = getIcon(app, 24);
              if (Icon) {
                return (
                  <li className="mr-5" key={index}>
                    <a target="_blank" href={app.url} rel="noreferrer">
                      <span>{Icon}</span>
                    </a>
                  </li>
                );
              }
              return (
                <li className="mr-5" key={index}>
                  <a target="_blank" href={app.url} rel="noreferrer" style={{ lineHeight: 0 }}>
                    <Image width={24} height={24} src={app.icon} layout="fixed" />
                  </a>
                </li>
              );
            })}
          </ul>
          <ul className={style.followMeIcon} style={{ marginBottom: 30 }}>
            <li className="mr-5">
              <a target="_blank" href="https://weibo.com/meituan?topnav=1&wvr=6&topsug=1" rel="noreferrer">
                <span>
                  <Weibo width={24} height={24} />
                </span>
              </a>
            </li>
            <li
              className="mr-5"
              onClick={() => {
                onClick('weiChat');
              }}
            >
              <span>
                <WeiChat width={24} height={24} />
              </span>
            </li>
            <li
              className="mr-5"
              onClick={() => {
                onClick('shipinghao');
              }}
            >
              <span>
                <ShiPinHao width={24} height={24} />
              </span>
            </li>
          </ul>
        </>
      ) : (
        <div />
      )}
      {/* <LangSelector current={locale} onClick={handleLocaleChange} isFixed={false} onClose={onClose} /> */}
      <Lang onClick={handleLocaleChange} />
    </div>
  );
};
