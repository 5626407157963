const KNBConfig = ['//s0.meituan.net/bs/knb/v1.8.3/knb.js', '//s0.meituan.net/bs/knb/v1.8.3/knb_wx.js'];

/**
 *  加载的KNB
 * @param url
 * @param callback
 */
const createScript = (url: string) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = url;
    script.onload = function load() {
      resolve(true);
    };

    document.head.appendChild(script);
  });
};

export class Loader {
  // 判断是否是微信
  static isWeixin() {
    const ua = window.navigator.userAgent.toLowerCase();
    return /(micromessenger)/i.test(ua);
  }

  private async load() {
    if (Loader.isWeixin()) {
      return createScript(KNBConfig[1]);
      // return createScript(KNBConfig[0]).then(() => createScript(KNBConfig[1]));
    }
    return createScript(KNBConfig[0]);
  }

  // 加载成功后，可以使用knb的桥能力
  init() {
    return this.load();
  }
}
