import React, { FC } from 'react';
import { Icon } from '../SvgIcon';

interface Props {
  className?: string;
}
const LinkOutIcon: FC<Props> = (props) => {
  const { className } = props;
  const LinkOutIconClassName = className;

  return <Icon className={LinkOutIconClassName} width={20} height={20} name="leftArrow" />;
};

LinkOutIcon.displayName = 'LinkOutIcon';
export default LinkOutIcon;
