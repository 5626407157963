import React from 'react';
import dynamic from 'next/dynamic';
// import { Modal } from 'antd';
import { Icon } from '../../../SvgIcon';
import { Image } from '../../../Image';
import style from './index.module.scss';

const Modal = dynamic(() => import('./Modal'), { ssr: false });

interface Props {
  logoSrc: string;
  visible: boolean;
  onClose(): void;
  title: string;
  tipsTitle: string;
}
export const LogoModal = (props: Props) => {
  const { title, visible, onClose, logoSrc, tipsTitle } = props;

  return (
    <Modal visible={visible} onClose={onClose}>
      <div className={style.logoHeader}>
        <div>{title}</div>
      </div>
      <div style={{ width: '160px' }}>
        <Image src={logoSrc} alt="logo" width={160} height={160} />
        <div className={style.logoFooter}>{tipsTitle}</div>
      </div>
      <div
        onClick={() => onClose()}
        className="absolute bottom-[-68px] left-[50%] flex h-11 w-11 translate-x-[-50%] cursor-pointer items-center justify-center rounded-full bg-white-15"
      >
        <Icon name="r-close" width={15} height={15} />
      </div>
    </Modal>
  );
};
