// 保存值,value可以为object,array等
export function setLSItem(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify({ value, time: Date.now() }));
}

// 删除
export function removeLSItem(key: string) {
  localStorage.removeItem(key);
}

// 1000 * 60 * 60 * 24
const getMillsByDay = (day: number) => 86400000 * day;

/**
 * 获取LS
 * @param expires 过期时间，默认为天，和js-cookie对齐
 */
export function getLSItem(key: string, expires?: number) {
  let obj = localStorage.getItem(key) || '{}';
  try {
    // 有可能ls会出问题，防止影响后续逻辑
    obj = JSON.parse(obj);
    const { value, time } = (obj as any) || {};
    const now = Date.now();
    // 如果填入了过期时间，对时间进行校验
    if (expires && now - time > getMillsByDay(expires)) {
      return undefined;
    }
    return value;
  } catch (err) {
    return undefined;
  }
}
