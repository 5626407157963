import React from 'react';
// import Image from 'next/image';
import { Image } from '../../../Image';
import styles from './Donation.module.scss';

interface Props {
  icon: string;
  title: string;
}

export const DonationIcon = (props: Props) => {
  const { icon, title } = props;

  return (
    <div className={styles.donation}>
      <div className={styles.donationIcon}>
        <Image src={icon} width={76} height={76} />
      </div>
      <div className={styles.donationTitle}>{title}</div>
    </div>
  );
};
