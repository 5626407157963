export default function ImageLoader({ src = '', width = 0, quality = 75 }) {
  return `${src}?w=${width}&q=${quality}`;
}

interface Args {
  imageUrl: string;
  width?: number;
  quality?: number;
  /* 是否透明底的图片：透明底的png图片在转成jpeg后会自动加上黑底 */
  isTransparent?: boolean;
}

export type ImgCompressOptions = Omit<Args, 'imageUrl'>;

/* 利用venus的图片服务压缩 */
export const getImgCompressSuffix = ({ imageUrl, width, isTransparent = false, quality = 80 }: Args) => {
  const isHashandle = imageUrl?.includes('@');
  const isJPG = imageUrl?.includes('.jpg') || imageUrl?.includes('.jpeg');
  const isPNG = imageUrl?.includes('.png');
  if (isHashandle) {
    return '';
  }
  let widthSuffix = '';
  if (width && width > 0) {
    widthSuffix = `${width}w_`;
  }
  if (isJPG) {
    return `@${widthSuffix}${quality}Q%7Cpr=1`;
  }
  // 如果是透明底：不使用渐进式jpg
  const format = isTransparent ? '' : 'format=jpeg%7C';
  const pr = isTransparent ? '' : '%7Cpr=1';
  if (isPNG) {
    return `@${format}${widthSuffix}${quality}Q${pr}`;
  }
  return width ? `@${width}w` : '';
};

export const compressImg = (args: Args) => {
  const { imageUrl } = args;
  const suffix = getImgCompressSuffix(args);
  return `${imageUrl}${suffix}`;
};

/* 针对特定模块的特定参数压缩 */
export const newsCoverCompressOptions = { quality: 60, width: 620 };
