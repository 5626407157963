import React from 'react';
import { useRouter } from 'next/router';
import { ZhLang } from './Zh';
import { EnLang } from './En';

export const Lang = () => {
  const { locale = 'zh-CN' } = useRouter();

  const isEn = locale === 'en-US';

  if (isEn) {
    return <EnLang />;
  }
  return <ZhLang />;
};
