import React, { useState, useRef } from 'react';
import classNames from 'classnames';
// import Link from 'next/link';
import { useRouter } from 'next/router';
import { Link } from '../../../Link';
import { MNav } from './MNav';
import { MNavLangSelector } from '../Lang/MSelector';
import { NavItemType } from '../../type';
import { Meituan, MeituanEn } from '../Icon';
import { useScroll } from '../hooks/useScroll';
import { useLogoLink } from '../hooks/useLogoLink';

import style from './nav.module.scss';

interface Props {
  items: Array<NavItemType>;
  visible: boolean;
  followMe: Record<string, string>;
  onClose(): void;
  onClick(type: string): void;
}

export const MNavBar = (props: Props) => {
  const { visible, onClose, items, followMe, onClick } = props;
  const [toggle, setToggle] = useState(false);
  const router = useRouter();
  const wrapRef = useRef<HTMLDivElement>(null);
  const { locale = 'zh-CN' } = router;
  const wrapperCls = classNames(style['mnav-menu-wrapper'], {
    [`${style['toggle-open']}`]: visible,
  });

  const renderIcon = (iconProps: any) => {
    if (locale === 'en-US') {
      return <MeituanEn {...iconProps} />;
    }
    return <Meituan {...iconProps} />;
  };

  useScroll(wrapRef);

  const jumpLink = useLogoLink();

  return (
    <div className={wrapperCls}>
      <nav className={style['mnav-menu-body']}>
        <Link href={jumpLink} legacyBehavior prefetch={false}>
          <a className={style['mnav-menu-body-link']} onClick={onClose}>
            {renderIcon({ ratio: 0.6 })}
          </a>
        </Link>
        <div
          className={classNames(style['mnav-menu-wrap'], {
            [`${style['toggle-open']}`]: toggle,
          })}
        >
          <div className={style['mnav-menu-body-wrap']}>
            <div className={style['mnav-menu-body-inner']}>
              <MNav
                items={items}
                toggle={toggle}
                onClose={onClose}
                onToggle={(toggleable) => {
                  setToggle(toggleable);
                }}
              />
            </div>
            <div className={style['mnav-menu-footer']}>
              <MNavLangSelector followMe={followMe} onClick={onClick} onClose={onClose} />
            </div>
          </div>
        </div>
      </nav>
      <div
        ref={wrapRef}
        className={style['mnav-menu-mask']}
        onClick={() => {
          onClose?.();
        }}
      />
    </div>
  );
};
