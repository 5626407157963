import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Image } from '../../../Image';
import { Lang } from './PC';
import { Transition } from '../Transition';
import { WeiChat, Weibo, ShiPinHao } from '../Icon';
import { Tooltips } from '../Tooltips';
import style from './index.module.scss';
import { Icon as SvgIcon } from '../../../SvgIcon';
import { getIcon } from './getAppIcon';

interface Props {
  followMe: Record<string, any>;
}

const offset: [number, number] = [0, 20];

export const NavLangSelector = (props: Props) => {
  const [toggle, setToggle] = useState(false);
  const divLeftRef = useRef(0);
  const { followMe } = props;
  const router = useRouter();

  const { locale = 'zh-CN' } = router;

  const panelCls = classNames(style['toggle-panel']);

  // const handleLocaleChange = (item: LangType) => {
  //   router.push(router.route, router.asPath, {
  //     locale: item.key,
  //   });
  // };
  const onToggle = (flag: boolean) => {
    setToggle(flag);
  };

  const onEnterStart = () => ({ height: 0 });
  const onEnter = (elm: HTMLElement) => {
    const rect = elm.getBoundingClientRect();
    const divleft = window.innerWidth - rect.left;
    const width = elm.scrollWidth;
    let translateLeft = 0;
    if (divleft < width + 20) {
      translateLeft = width - divleft + 20;
    }
    if (translateLeft) {
      divLeftRef.current = translateLeft;
    }
    return { height: elm.scrollHeight, transform: `translateX(-${translateLeft}px)` };
  };
  const onEnterEnd = () => {
    return { transform: `translateX(-${divLeftRef.current}px)` };
  };
  const onLeaveStart = (elm: HTMLElement) => ({
    height: elm.offsetHeight,
    transform: `translateX(-${divLeftRef.current}px)`,
  });
  const onLeave = () => {
    divLeftRef.current = 0;
    return { height: 0 };
  };
  return (
    <div className="hidden h-20 items-center xl:absolute xl:right-10 xl:top-0 xl:flex">
      {locale !== 'en-US' ? (
        <div
          className={classNames(style.followMe, {
            [`${style.current}`]: toggle,
          })}
          onMouseEnter={() => {
            onToggle(true);
          }}
          onMouseLeave={() => {
            onToggle(false);
          }}
        >
          <a className="cursor-pointer" aria-label="meituan app download">
            <span className="header-links-color">{followMe.navTitle}</span>
            <span className={style.arrow}>
              <SvgIcon width={20} height={20} className="inline-block align-middle" name="deepArrow" />
            </span>
          </a>
          <Transition
            visible={toggle}
            name="dropdown"
            enable
            enterEndCls={style.block}
            leaveEndCls={style.none}
            onEnter={onEnter}
            onEnterStart={onEnterStart}
            onEnterEnd={onEnterEnd}
            onLeave={onLeave}
            onLeaveStart={onLeaveStart}
          >
            {({ className, style: animateStyle }, ref) => {
              return (
                <div className={classNames(panelCls, className)} style={animateStyle} ref={ref}>
                  <div className={style['toggle-panel-bg']}>
                    <ul className={style['toggle-panel-content']}>
                      {followMe.app.map((app: any, index: number) => {
                        const Icon = getIcon(app);
                        if (Icon) {
                          return (
                            <li key={index}>
                              <Tooltips
                                placement="left"
                                className={style['link-item']}
                                offset={offset}
                                content={
                                  <div className={style['link-item-icon']} style={{ width: 76, height: 76 }}>
                                    <Image src={app.QRCode} alt="logo" width={76} height={76} />
                                  </div>
                                }
                              >
                                <span className="absolute left-2 top-[10px]">{Icon}</span>
                                <span
                                  // style={{
                                  //   background: `url(${followMe.weiboIcon}) no-repeat -1000px -1000px`,
                                  // }}
                                  className={style['link-item-title']}
                                >
                                  {app.name}
                                </span>
                              </Tooltips>
                            </li>
                          );
                        }
                        return (
                          <li key={index}>
                            <Tooltips
                              placement="left"
                              className={style['link-item']}
                              offset={offset}
                              content={
                                <div className={style['link-item-icon']} style={{ width: 76, height: 76 }}>
                                  <Image src={app.QRCode} alt="logo" width={76} height={76} />
                                </div>
                              }
                            >
                              <span className="absolute left-2 top-[10px]">
                                <Image layout="fixed" width={20} height={20} src={app.icon} />
                              </span>
                              <span
                                // style={{
                                //   background: `url(${followMe.weiboIcon}) no-repeat -1000px -1000px`,
                                // }}
                                className={style['link-item-title']}
                              >
                                {app.name}
                              </span>
                            </Tooltips>
                          </li>
                        );
                      })}

                      <li>
                        <Tooltips
                          placement="left"
                          className={style['link-item']}
                          offset={offset}
                          content={
                            <div className={style['link-item-icon']} style={{ width: 76, height: 76 }}>
                              <Image src={followMe.weiboIcon} alt="logo" width={76} height={76} />
                            </div>
                          }
                        >
                          <span className="absolute left-2 top-[10px]">
                            <Weibo />
                          </span>
                          <span
                            // style={{
                            //   background: `url(${followMe.weiboIcon}) no-repeat -1000px -1000px`,
                            // }}
                            className={style['link-item-title']}
                          >
                            {followMe.weiBoTitle}
                          </span>
                        </Tooltips>
                      </li>
                      <li>
                        <Tooltips
                          placement="left"
                          className={style['link-item']}
                          offset={offset}
                          content={
                            <div className={style['link-item-icon']} style={{ width: 76, height: 76 }}>
                              <Image src={followMe.weiChatIcon} alt="logo" width={76} height={76} />
                            </div>
                          }
                        >
                          <span className="absolute left-2 top-[10px]">
                            <WeiChat />
                          </span>
                          <span
                            // style={{
                            //   background: `url(${followMe.weiChatIcon}) no-repeat -1000px -1000px`,
                            // }}
                            className={style['link-item-title']}
                          >
                            {followMe.weiChatTitle}
                          </span>
                        </Tooltips>
                      </li>
                      <li>
                        <Tooltips
                          placement="left"
                          className={style['link-item']}
                          offset={offset}
                          content={
                            <div className={style['link-item-icon']} style={{ width: 76, height: 76 }}>
                              <Image src={followMe.shipinghaoIcon} alt="logo" width={76} height={76} />
                            </div>
                          }
                        >
                          <span className="absolute left-2 top-[10px]">
                            <ShiPinHao />
                          </span>
                          <span
                            // style={{
                            //   background: `url(${followMe.shipinghaoIcon}) no-repeat -1000px -1000px`,
                            // }}
                            className={style['link-item-title']}
                          >
                            {followMe.shipinghaoTitle}
                          </span>
                        </Tooltips>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            }}
          </Transition>
        </div>
      ) : null}
      {/* 临时英文版本不支持，所以先展示最基础版本 */}
      {/* <LangSelector current={locale} onClick={handleLocaleChange} theme={theme} isFixed={isFixed} /> */}
      <Lang />
    </div>
  );
};
