import React from 'react';
import { Icon } from '../../../SvgIcon';

interface Props {
  width?: number;
  height?: number;
}

export const WeiChat = (props: Props) => {
  const { width = 20, height = 20 } = props;
  return <Icon name="wechat" width={width} height={height} />;
};
