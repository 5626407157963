import React from 'react';
import { ErrorPage } from './ErrorPage';

interface Props {
  children: React.ReactNode;
}

export class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    window.owl &&
      window.owl(
        'addError',
        {
          name: `render-component-error|${error.message}`,
          msg: errorInfo.componentStack,
        },
        {
          category: 'customError',
          level: 'error',
          combo: true,
        },
      );
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <ErrorPage />;
    }

    return children;
  }
}
