import { isProd } from './env';

// https://docs.sankuai.com/lx/web/index-5/#1pvpd

export const getAnalyticsTag = () => {
  return {
    __html: `
    !(function (win, doc, ns) {
      win['_MeiTuanALogObject'] = ns;
      if (!win[ns]) {
          var _LX = function () {
              var t = function(){
                  var inst = function(){
                      inst.q.push([arguments, +new Date()]);
                  }
                  inst.q = [];
                  t.q.push([arguments, inst]);
                  return inst;
              }
              t.q = [];
              t.t = +new Date();
              _LX.q.push([arguments, t]);
              return t;
          };
          _LX.q = _LX.q || [];
          _LX.l = +new Date();
          win[ns] = _LX;
      }
  }) (window, document, 'LXAnalytics');
  LXAnalytics('config', {
      defaultCategory:'donation', // 页面默认通道，将 channelIdentifier 替换为您的上报通道标识
      appName:'meituanOfficeSite', // 页面应用名，将 appIdentifier 替换为您在配置平台配置的应用标识
      isSPA: false
  });
    `,
  };
};

export interface LXPageParams {
  cid: string;
  custom?: any;
  env?: any;
}

export interface LXModuleParams {
  bid: string;
  custom?: any;
  options?: any;
}

export function pageView({ cid, custom = null, env = null }: LXPageParams): any {
  /* 线下不上报 */
  if (!isProd()) {
    return;
  }

  if (!window.LXAnalytics) return;

  const tracker = window.LXAnalytics('getTracker');
  // eslint-disable-next-line consistent-return
  return tracker('pageView', custom ? { custom } : null, env, cid);
}

/**
 * 灵犀MC事件
 * @param pageCase
 * @returns
 */
export const moduleClickFactory =
  (pageCase: any) =>
  ({ bid, custom, options }: { bid: string; custom?: Record<string, any>; options?: Record<string, any> }) => {
    if (!pageCase) return;
    pageCase('moduleClick', bid, custom ? { custom } : null, options);
  };

export const moduleViewFactory =
  (pageCase: any) =>
  ({ bid, custom }: { bid: string; custom?: Record<string, any> }) => {
    if (!pageCase) return;
    pageCase('moduleView', bid, custom ? { custom } : null);
  };
