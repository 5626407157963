import { LangType } from './LangSelector';

export const items: Array<LangType> = [
  {
    title: '简体中文',
    key: 'zh-CN',
  },
  {
    title: '繁體中文',
    key: 'zh-HK',
  },
  {
    title: 'English',
    key: 'en-US',
  },
];
