import React from 'react';
import classNames from 'classnames';
import { Icon } from '../../../SvgIcon';

export const Meituan = () => {
  return (
    <span>
      <Icon name="meituan" width={113} height={40} className={classNames('hidden lg:block')} />
      <Icon name="meituan" width={85} height={30} className={classNames('block lg:hidden')} />
    </span>
  );
};
