import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { debounce } from 'lodash-es';
import { BreakPoint, BreakPointSize, ScreenSizeProvider } from './constant';

export * from './constant';
export * from './use-screen-size';

interface IProps {
  children: React.ReactElement | React.ReactElement[];
}

const mobileSize = [BreakPoint.lg, BreakPoint.md, BreakPoint.sm];

function ScreenSize(props: IProps) {
  const isClient = typeof window === 'object';
  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
    };
  }, [isClient]);

  const { children } = props;
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });

  const screen = useMemo(() => {
    if (screenSize.width < BreakPointSize.sm) {
      return BreakPoint.sm;
    }
    if (screenSize.width >= BreakPointSize.sm && screenSize.width < BreakPointSize.md) {
      return BreakPoint.sm;
    }
    if (screenSize.width >= BreakPointSize.md && screenSize.width < BreakPointSize.lg) {
      return BreakPoint.md;
    }
    if (screenSize.width >= BreakPointSize.lg && screenSize.width < BreakPointSize.xl) {
      return BreakPoint.lg;
    }
    return BreakPoint.xl;
  }, [screenSize]);

  const contextVal = useMemo(() => {
    return {
      size: screenSize,
      screen,
      isMobile: mobileSize.indexOf(screen) >= 0,
    };
  }, [screenSize, screen]);

  useEffect(() => {
    if (!isClient) return () => {};
    // 挂载的时候执行一次
    setScreenSize(getSize());
    const handleResize = debounce(() => {
      setScreenSize(getSize());
    }, 30);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [getSize, isClient]);

  return <ScreenSizeProvider.Provider value={contextVal}>{children}</ScreenSizeProvider.Provider>;
}

export default ScreenSize;
