import React from 'react';
import classNames from 'classnames';
// import Link from 'next/link';
import { Link } from '../../../Link';
import { NavPanel } from './NavPanel';
import styles from './NavItem.module.scss';
import { Icon } from '../../../SvgIcon';

interface Props {
  uid: string; // 菜单的UId
  title: string; // 菜单的名称
  path: string; // 跳转地址
  h5Out?: boolean; // 仅H5外跳
  out?: boolean; // 是否外跳
  selectKey: string; // 选中的Key
  isHover: boolean; // 判断是否hover状态
  hasChild?: boolean; // 是否有子元素
  childData?: any; // 子面板
  // children?(key: string): React.ReactNode; // 渲染下层数据
  onClick?(key: string): void;
  onToggle?(key: string): void;
}

export const NavItem = (props: Props) => {
  const { title, path, h5Out, selectKey, hasChild, uid, onToggle, isHover, childData, out } = props;

  // item的Class
  const itemCls = classNames(styles['nav-item'], {
    [`${styles['nav-item-current']}`]: !isHover && selectKey === uid,
    [`${styles['nav-item-hover']}`]: isHover,
  });
  // 箭头的class
  const arrowCls = classNames(styles.arrow, {
    [`${styles.current}`]: isHover,
  });

  return (
    <li
      className={itemCls}
      onMouseEnter={() => {
        onToggle?.(uid);
      }}
      onMouseLeave={() => {
        onToggle?.('');
      }}
    >
      {out && !h5Out ? (
        <a
          href={path}
          target="_blank"
          rel="noreferrer"
          className={classNames(styles['nav-item-a-link'], 'header-links-color')}
        >
          <span className={styles.sliderWrap}>
            <span>{title}</span>
            <span className={styles.slider} />
          </span>
        </a>
      ) : (
        <Link href={path} legacyBehavior scroll={false} prefetch={false}>
          <a className={classNames(styles['nav-item-a-link'], 'header-links-color')}>
            <span className={styles.sliderWrap}>
              <span>{title}</span>
              <span className={styles.slider} />
            </span>
          </a>
        </Link>
      )}
      {hasChild && <Icon name="deepArrow" width={20} height={20} className={arrowCls} />}
      {childData && <NavPanel {...childData} visible={isHover} />}
    </li>
  );
};
