import React, { useMemo, useState } from 'react';
// import Link from 'next/link';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { ThemeType, useHeaderTheme } from '../../../Layout/useTheme';
import { Link } from '../../../Link';
import { Collapse } from './Collapse';
import { CopyRight } from './Copyright';
import { Meituan, WeiChat, ShiPinHao, Weibo, MeituanEn } from '../Icon';
import style from './footer.module.scss';
import { useScreenSize } from '../../../ScreenSize';
import { SubNavMenu } from '../../type';
import { useLogoLink } from '../hooks/useLogoLink';

interface Props {
  items: Array<SubNavMenu>;
  followMe: Record<string, any>;
  copyright: Record<string, string>;
}

const fillColor = (isDark: boolean) => (isDark ? '#ffffff' : '#111925');

export const Footer = (props: Props) => {
  const { theme } = useHeaderTheme();
  const { size } = useScreenSize(); // 页面尺寸属性
  const { items, followMe, copyright } = props;
  const isDark = theme === ThemeType.Dark;
  const [weiChatVisibe, setWeiChatVisibe] = useState(false);
  const [weiboVisibe, setWeiboVisibe] = useState(false);
  const [shipinghaoVisible, setShipinghaoVisible] = useState(false);
  const router = useRouter();
  const { locale = 'zh-CN' } = router;
  const isMoblie = useMemo(() => {
    if (size.width < 1024) {
      return true;
    }
    return false;
  }, [size]);

  const renderIcon = (iconProps: any) => {
    if (locale === 'en-US') {
      return <MeituanEn {...iconProps} />;
    }
    return <Meituan />;
  };
  // 组装哈关于我们的菜单数据

  const followMeData = useMemo(() => {
    return {
      title: followMe.navTitle,
      items: [
        {
          title: followMe.weiBoTitle,
          path: 'https://weibo.com/meituan?topnav=1&wvr=6&topsug=1',
          out: true,
          hoverIcon: followMe.weiboIcon,
          visible: weiboVisibe,

          icon: <Weibo />,
          onOpen: () => {
            setWeiboVisibe(true);
          },
          onClose: () => {
            setWeiboVisibe(false);
          },
        },
        {
          title: followMe.weiChatTitle,
          path: '/',
          icon: <WeiChat />,
          hoverIcon: followMe.weiChatIcon,
          visible: weiChatVisibe,
          modalTitle: followMe.modal.weiChatTitle,
          tipsTitle: followMe.modal.tipsTitle,
          onOpen: () => {
            setWeiChatVisibe(true);
          },
          onClose: () => {
            setWeiChatVisibe(false);
          },
        },
        {
          title: followMe.shipinghaoTitle,
          path: '/',
          icon: <ShiPinHao />,
          modalTitle: followMe.modal.shipinghaoTitle,
          tipsTitle: followMe.modal.tipsTitle,
          hoverIcon: followMe.shipinghaoIcon,
          visible: shipinghaoVisible,
          onOpen: () => {
            setShipinghaoVisible(true);
          },
          onClose: () => {
            setShipinghaoVisible(false);
          },
        },
      ],
    };
  }, [followMe, weiChatVisibe, shipinghaoVisible, weiboVisibe]);

  const ratio = useMemo(() => {
    if (isMoblie) return 0.75;
    return 1;
  }, [isMoblie, size]);

  const jumpLink = useLogoLink();

  const isEn = locale === 'en-US';

  return (
    <footer className={classNames(style.footer, { [`${style['footer-dark']}`]: isDark })}>
      {isEn ? null : (
        <div className={style.footerInner}>
          <Collapse menus={items} followMe={followMeData} isDark={isDark} app={followMe.app} />
          <div className={style['footer-logo-container']}>
            <Link href={jumpLink} legacyBehavior scroll={false} prefetch={false}>
              <a>{renderIcon({ ratio, fillColor: fillColor(isDark) })}</a>
            </Link>
          </div>
        </div>
      )}
      <CopyRight isEn={isEn} copyright={copyright} isMoblie={isMoblie} />
    </footer>
  );
};
