import React, { useRef } from 'react';
// import Image from 'next/image';
import classNames from 'classnames';
import { Image } from '../../../Image';
import { Tooltips } from '../Tooltips';
import style from './index.module.scss';
import { getIcon } from './useIcon';

interface Props {
  items: Array<any>;
  isDark?: boolean;
  app: Array<any>;
}

const offset: [number, number] = [0, 10];

const IconWrap = (props: any) => {
  const { item } = props;
  const liRef = useRef<HTMLLIElement>(null);
  return (
    <li className={style.linkItem} ref={liRef}>
      <Tooltips
        className={style.linkItemWrap}
        placement="left"
        offset={offset}
        triggerTarget={liRef}
        content={
          <div className={style.iconWrap}>
            <Image src={item.hoverIcon} alt="logo" width={90} height={90} />
          </div>
        }
      >
        <span className={style.icon}>{item.icon}</span>
      </Tooltips>
      <span className={style.title}>{item.title}</span>
    </li>
  );
};

const IconAppWrap = (props: any) => {
  const { item } = props;
  const liRef = useRef<HTMLLIElement>(null);
  const renderIcon = () => {
    const Icon = getIcon(item);
    if (Icon) {
      return <span className={style.icon}>{Icon}</span>;
    }
    return <Image src={item.icon} layout="fixed" width={20} height={20} />;
  };
  return (
    <li className={style.linkItem} ref={liRef}>
      <Tooltips
        className={style.linkItemWrap}
        placement="left"
        offset={offset}
        triggerTarget={liRef}
        content={
          <div className={style.iconWrap}>
            <Image src={item.QRCode} alt="logo" width={90} height={90} />
          </div>
        }
      >
        {/* <span className={style.icon}>
          <AppWaimai />
        </span> */}
        {renderIcon()}
        {/* <Image src={item.icon} layout="fixed" width={20} height={20} /> */}
      </Tooltips>
      <span className={style.title}>{item.name}</span>
    </li>
  );
};

export const PCFollowMe = (props: Props) => {
  const { items, isDark = false, app = [] } = props;

  const cls = classNames(style.followMe, {
    [`${style['followMe-dark']}`]: isDark,
  });
  return (
    <div className="flex">
      <ul className={classNames(cls, 'mr-10')}>
        {app.map((item, key) => {
          return <IconAppWrap item={item} key={key} />;
        })}
      </ul>
      <ul className={cls}>
        {items.map((item, key) => {
          const { hoverIcon } = item;
          if (hoverIcon) {
            return <IconWrap item={item} key={key} />;
          }
          return (
            <li className={style.linkItem} key={key}>
              <a href={item.path} target="_blank" rel="noreferrer" className={style.linkItemWrap}>
                <span className={style.icon}>{item.icon}</span>
                <span className={style.title}>{item.title}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
