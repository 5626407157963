import { Env } from './type';

export const env = process.env.NEXT_ENV || Env.staging;

export const clientEnv = process.env.NEXT_PUBLIC_ENV;

export const getEnv = () => {
  return env as Env;
};

/* 仅client端 */
export const isProd = () => {
  return clientEnv === Env.production;
};

export { Env };
