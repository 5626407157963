import React, { useContext, useEffect, useRef, useMemo, useState } from 'react';
import { pageView, moduleClickFactory, moduleViewFactory } from '../../util/lx';

export const LxContext = React.createContext<{
  moduleClick(...args: any[]): void;
  moduleView(...args: any[]): void;
}>({} as any);

interface Props {
  children: React.ReactNode;
  lxCustom?: Record<string, any>;
  screen: any;
  size: any;
}

export const LxContainer = (props: Props) => {
  const { lxCustom, children, screen, size } = props;
  const [init, setInit] = useState(false);
  const pageCase = useRef();

  useEffect(() => {
    if (size.width === 0) {
      return;
    }
    setInit(true);
    if (pageCase.current) return;
    pageCase.current = pageView({
      cid: lxCustom?.cid,
      custom: {
        ...lxCustom,
        screen,
      },
    });
  }, [lxCustom, size, screen]);

  const contextValue = useMemo(() => {
    if (!init) return {};
    return {
      moduleClick: moduleClickFactory(pageCase.current),
      moduleView: moduleViewFactory(pageCase.current),
    };
  }, [init]);

  return <LxContext.Provider value={contextValue as any}>{children}</LxContext.Provider>;
};

export const useModuleClick = () => {
  const { moduleClick } = useContext(LxContext);
  return moduleClick;
};
export const useModuleView = () => {
  const { moduleView } = useContext(LxContext);
  return moduleView;
};
