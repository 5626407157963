import { useRouter } from 'next/router';

/**
 *
 * 兼容英文版本和中文版本logo点击的跳转链接差异
 * @returns
 */
export const useLogoLink = () => {
  const { locale = 'zh-CN' } = useRouter();
  if (locale === 'en-US') {
    return '/en-US/about-us';
  }
  return '/';
};
