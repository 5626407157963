import React from 'react';
import { useRouter } from 'next/router';

import { EnLang } from './En';
import { ZhLang } from './Zh';

export const Lang = ({ onClick }: any) => {
  const { locale = 'zh-CN' } = useRouter();

  const isEn = locale === 'en-US';

  if (isEn) {
    return <EnLang />;
  }
  return <ZhLang onClick={onClick} />;
};
