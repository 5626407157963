import React, { useState } from 'react';
import classNames from 'classnames';
import { LangType } from '../LangSelector';
import { Icon } from '../../../../SvgIcon';
import styles from './index.module.scss';

interface Props {
  item: LangType;
  renderChild(toggle: boolean, setToggle: any): React.ReactNode;
}

export const DropDown = (props: Props) => {
  const { item, renderChild } = props;

  const [toggle, setToggle] = useState(false);

  return (
    <div
      className={classNames(styles.langContainer, {
        [`${styles.current}`]: toggle,
      })}
      onMouseEnter={() => {
        setToggle(true);
      }}
      onMouseLeave={() => {
        setToggle(false);
      }}
    >
      <a className="cursor-pointer" aria-label="language selector">
        {item.title}
        {/* <span className={}> */}
        <Icon name="deepArrow" width={20} height={20} className={classNames(styles.arrow)} />
        {/* </span> */}
      </a>
      {renderChild(toggle, setToggle)}
    </div>
  );
};
