import React from 'react';
// import Link from 'next/link';
import classNames from 'classnames';
import { Link } from '../../../Link';
import style from './footer.module.scss';
import { Transition } from '../Transition';

import { LinkItem } from '../../type';

interface Props {
  mkey: string;
  title: string;
  items: Array<LinkItem>;
  visible: boolean;
  enable: boolean; // 是否启动动画。
  isH5: boolean;
  isDark: boolean;
  toggle(key: string): void;
}

export const CollapsePanel = (props: Props) => {
  const { title, items, mkey, toggle, visible, enable, isH5, isDark = false } = props;

  const onToggle = () => {
    toggle(mkey);
  };

  const panelCls = classNames(style['footer-collapse-panel'], {
    [`${style['toggle-open']}`]: visible,
  });

  const onEnterStart = () => {
    return {
      height: 0,
    };
  };
  const onEnter = (elm: HTMLElement) => {
    return {
      height: elm.scrollHeight,
    };
  };
  const onLeaveStart = (elm: HTMLElement) => {
    return {
      height: elm.offsetHeight,
    };
  };
  const onLeave = () => {
    return {
      height: 0,
    };
  };
  // 控制动画展
  return (
    <div className={panelCls}>
      <div className={style['footer-collapse-panel-title']} onClick={onToggle}>
        <h6 className={style['footer-collapse-panel-title-con']}>{title}</h6>
        <div className={style['footer-arrow']}>
          <svg width="16px" height="16px" viewBox="0 0 32 32">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.5">
              <g fill={isDark ? '#fff' : '#000000'} fillRule="nonzero">
                <g transform="translate(16.000000, 16.000000) rotate(-90.000000) translate(-16.000000, -16.000000) ">
                  <path d="M22,28 C21.6092966,28.3903351 20.9761858,28.3901858 20.5856666,27.9996666 L9.05733334,16.4713333 L9.05733334,16.4713333 C8.7970787,16.2110001 8.7970787,15.7889999 9.05733334,15.5286667 L20.5853526,4.00001927 C20.9759807,3.60936985 21.6093313,3.6093526 21.9999807,3.99998073 C21.9999872,3.99998715 21.9999936,3.99999358 22,4 C22.3906264,4.39062636 22.3906264,5.02395682 22,5.41458318 C21.9999861,5.4145971 21.9999722,5.41461101 21.9999582,5.41462493 L11.4133333,16 L11.4133333,16 L22.0003124,26.5863541 C22.3905937,26.9766124 22.3906124,27.6093647 22.0003541,27.999646 C22.0002361,27.9997641 22.0001181,27.999882 22,28 Z" />
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <Transition
        enable={enable}
        visible={visible}
        name="collapsed"
        onEnterStart={onEnterStart}
        onEnter={onEnter}
        onLeaveStart={onLeaveStart}
        onLeave={onLeave}
        leaveEndCls="hidden"
        enterEndCls="block"
      >
        {({ className, style: animateStyle }, ref) => {
          return (
            <div
              className={classNames(style['footer-collapse-panel-submenu'], className)}
              style={animateStyle}
              ref={ref}
            >
              <ul>
                {(items || []).map((item, index) => {
                  const { path, out = false, h5Path, title: itemTitle } = item;
                  // 兼容所有外链地址
                  const outPath = isH5 ? h5Path || path : path;
                  return (
                    <li className={style['footer-collapse-panel-link']} key={index}>
                      {out ? (
                        <a href={outPath} target="_blank" rel="noreferrer" className={style.footerLink}>
                          <span>{itemTitle}</span>
                        </a>
                      ) : (
                        <Link href={path} legacyBehavior scroll={false} prefetch={false}>
                          <a className={style.footerLink} target="_blank">
                            <span>{itemTitle}</span>
                          </a>
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        }}
      </Transition>
    </div>
  );
};
