import { useRef, useEffect } from 'react';
import { ThemeType, useHeaderTheme } from '../../../Layout/useTheme';

export const useTheme = () => {
  const currentRef = useRef<ThemeType>();
  // 获取最新的主题
  const { theme, isFixed, setFixed } = useHeaderTheme();
  // 记录每次变化后的theme
  currentRef.current = theme;

  // header是否开始滚动
  useEffect(() => {
    const handler = () => {
      const { scrollTop } = document.documentElement;
      setFixed(scrollTop > 0);
    };
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [setFixed]);

  return {
    fixed: isFixed,
    theme,
  };
};
