import React, { Fragment } from 'react';
import style from './index.module.scss';
import { LogoModal } from '../Lang/LogoModal';
import { Image } from '../../../Image';
import { getIcon } from './useIcon';

interface Props {
  items: Array<any>;
  app: Array<any>;
}

export const MFollowMe = (props: Props) => {
  const { items, app } = props;

  return (
    <div className={style.mfollowMe}>
      {app.map((item, index) => {
        const Icon = getIcon(item);
        if (Icon) {
          return (
            <span className={style.miconWrap} onClick={item.onOpen} key={index}>
              <a href={item.url} target="_blank" rel="noreferrer" style={{ lineHeight: '0' }}>
                {Icon}
              </a>
            </span>
          );
        }
        return (
          <span key={index} className={style.miconWrap}>
            <a href={item.url} target="_blank" rel="noreferrer" style={{ lineHeight: '0' }}>
              <Image src={item.icon} layout="fixed" width={20} height={20} />
            </a>
          </span>
        );
      })}
      {items.map((item, index) => {
        if (item.hoverIcon && !item.out) {
          return (
            <Fragment key={index}>
              <span className={style.miconWrap} onClick={item.onOpen}>
                {item.icon}
              </span>
              <LogoModal
                key={`modal-${index}`}
                logoSrc={item.hoverIcon}
                visible={item.visible}
                onClose={item.onClose}
                title={item.modalTitle}
                tipsTitle={item.tipsTitle}
              />
            </Fragment>
          );
        }
        return (
          <span key={index} className={style.miconWrap}>
            <a href={item.path} target="_blank" rel="noreferrer">
              {item.icon}
            </a>
          </span>
        );
      })}
    </div>
  );
};
