import { useMemo } from 'react';
import { NavItemType, SubNavMenu } from '../Common/type';

export const useHeaderDataKey = (data: Array<NavItemType>): Array<NavItemType> => {
  return useMemo(() => {
    return data?.map?.((item, rowIndex) => {
      const rowKey = `row-${rowIndex}`;
      if (item.hasChild) {
        // 递归的给下面的内容全部加上key
        item.childData?.navArrs.forEach((subMenu, subMenuIndex) => {
          subMenu.items.forEach((subMenuItem, itemIndex) => {
            subMenuItem.key = `${rowKey}-${subMenuIndex}-${itemIndex}`;
          });
        });
      }
      return {
        ...item,
        key: rowKey,
      };
    });
  }, [data]);
};

export const useFooterDataKey = (data: Array<SubNavMenu>): Array<SubNavMenu> => {
  return useMemo(() => {
    return data?.map?.((item, index) => {
      item.items.forEach((menuItem, itemIndex) => {
        menuItem.key = `row-${index}-${itemIndex}`;
      });
      return {
        ...item,
        key: `row-${index}`,
      };
    });
  }, [data]);
};

export const useFooterLinksDataKey = (data: Array<any>): Array<any> => {
  return useMemo(() => {
    return data?.map?.((item, index) => {
      return {
        ...item,
        key: `row-${index}`,
      };
    });
  }, [data]);
};
