import React from 'react';
import { OutLink } from '../PC/OutLink';
import styles from './index.module.scss';

export const EnLang = () => {
  return (
    <div className={styles.lang}>
      <OutLink title="中文版本" href="/" />
    </div>
  );
};
