import React from 'react';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
}
export const Button = (props: Props) => {
  const { children, onClick } = props;

  const handleClick = () => {
    onClick?.();
  };
  return (
    <button
      type="button"
      className="cursor-pointer rounded-xl bg-[#ffd100] px-11 py-4 text-xl leading-8 text-black-dark hover:bg-[#ffe366] active:bg-[#d99100]"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
