import React from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { OutLink } from '../PC/OutLink';
import { items } from '../constants';
import styles from './index.module.scss';

export const ZhLang = ({ onClick }: any) => {
  const { locale = 'zh-CN' } = useRouter();

  return (
    <div className={styles.lang}>
      <div className={styles.langWrap}>
        <span
          className={classNames(styles.item, {
            [`${styles.itemCurrent}`]: locale === items[0].key,
          })}
          onClick={() => onClick(items[0])}
        >
          {items[0].title}
        </span>{' '}
        /{' '}
        <span
          className={classNames(styles.item, {
            [`${styles.itemCurrent}`]: locale === items[1].key,
          })}
          onClick={() => onClick(items[1])}
        >
          {items[1].title}
        </span>
      </div>
      <div>
        <OutLink title="English Version" href="/en-US/about-us" />
      </div>
    </div>
  );
};
