import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FooterSingeMenuType, LinkItem } from '../../type';
import { CollapsePanel } from './CollapsePanel';
import { PCFollowMe, MFollowMe } from '../FollowMe';
import style from './footer.module.scss';
// 获取屏幕大小
import { useScreenSize } from '../../../ScreenSize';

interface Props {
  menus: Array<FooterSingeMenuType>;
  followMe: { title: string; items: Array<LinkItem> };
  isDark: boolean;
  app: Array<any>;
}

export const Collapse = (props: Props) => {
  const { menus, followMe, isDark, app } = props;
  const [displayKey, setDisplayKey] = useState<string>();
  const { size } = useScreenSize(); // 页面尺寸属性

  const isMoblie = useMemo(() => {
    if (size.width <= 1024) {
      return true;
    }
    return false;
  }, [size]);
  // 切换菜单
  const onToggle = useCallback(
    (key: string) => {
      if (key === displayKey) {
        setDisplayKey('');
      } else {
        setDisplayKey(key);
      }
    },
    [displayKey],
  );

  useEffect(() => {
    // 清空展开状态
    setDisplayKey('');
  }, [isMoblie]);

  return (
    <div className="flex flex-col-reverse lg:flex-row">
      <div className="flex flex-col lg:flex-row">
        {(menus || []).map((subMenu, index) => {
          return (
            <CollapsePanel
              enable={isMoblie}
              isH5={isMoblie}
              isDark={isDark}
              {...subMenu}
              visible={subMenu.title === displayKey}
              key={index}
              mkey={subMenu.title}
              toggle={onToggle}
            />
          );
        })}
      </div>
      <div className={style.followMe}>
        <h2 className={style.followMeTitle}>{followMe.title}</h2>
        <MFollowMe items={followMe.items} app={app} />
        <PCFollowMe items={followMe.items} isDark={isDark} app={app} />
      </div>
    </div>
  );
};
